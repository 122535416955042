import { useEffect } from "react";

const useSEO = ({ title, description, keywords, canonical, image, alt }) => {
  useEffect(() => {
    // Set document title
    if (title) {
      document.title = title;
    }

    // Set meta description
    const metaDescription = document.querySelector("meta[name='description']");
    if (metaDescription) {
      metaDescription.content = description || '';
    } else {
      const metaDesc = document.createElement('meta');
      metaDesc.name = 'description';
      metaDesc.content = description || '';
      document.head.appendChild(metaDesc);
    }

    // Set meta keywords
    const metaKeywords = document.querySelector("meta[name='keywords']");
    if (metaKeywords) {
      metaKeywords.content = keywords || '';
    } else {
      const metaKey = document.createElement('meta');
      metaKey.name = 'keywords';
      metaKey.content = keywords || '';
      document.head.appendChild(metaKey);
    }

    // Set canonical link
    const linkCanonical = document.querySelector("link[rel='canonical']");
    if (linkCanonical) {
      linkCanonical.href = canonical || '';
    } else if (!linkCanonical) {
      const link = document.createElement('link');
      link.rel = 'canonical';
      link.href = canonical || '';
      document.head.appendChild(link);
    }

    // Set Open Graph image
    const ogImage = document.querySelector("meta[property='og:image']");
    if (ogImage) {
      ogImage.content = image || '';
    } else {
      const ogImg = document.createElement('meta');
      ogImg.setAttribute('property', 'og:image');
      ogImg.content = image || '';
      document.head.appendChild(ogImg);
    }

    // Set Open Graph image alt text
    const ogImageAlt = document.querySelector("meta[property='og:image:alt']");
    if (ogImageAlt) {
      ogImageAlt.content = alt || '';
    } else {
      const ogImgAlt = document.createElement('meta');
      ogImgAlt.setAttribute('property', 'og:image:alt');
      ogImgAlt.content = alt || '';
      document.head.appendChild(ogImgAlt);
    }

    // Cleanup function to reset values on component unmount
    return () => {
      if(document.title){
        document.title = '';
      }
      if(metaDescription?.content){
        metaDescription.content = '';
        }
      if(metaKeywords?.content){
        metaKeywords.content = '';
        }
      if(linkCanonical?.href){
        linkCanonical.href = '';
        }
      if(ogImage?.content){
        ogImage.content = '';
        }
      if(ogImageAlt?.content){
        ogImageAlt.content = '';
      }
    };
  }, [title, description, keywords, canonical, image, alt]);
};

export default useSEO