import React, { lazy } from "react";
import App from "./App";
import WebProtectedLayout from "./layout/WebProtectedLayout";
import WIthoutHeaderFooterLayout from "./layout/WIthoutHeaderFooterLayout";
import { adminRoot } from "./config/config";
import AdminLayout from "./layout/AdminLayout";
import Product from "./view/Product";
import PrivacyPolicy from "./view/PrivacyPolicy.js";

// SECTION OTHER ROUTES
const ViewError = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/Error")
);
const ViewUnauthorized = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/Unauthorized")
);
const ViewNotFound = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/NotFound")
);

//!SECTION

// SECTION CUSTOMER OR WEB ROUTES
const ViewLogin = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/Login")
);
const ViewRegister = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/Register")
);
const ViewHome = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/Home")
);
const ProductList = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/ProductList")
);
const SingleProduct = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/Product")
);
const DataVisualization = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/data-visualization/DataVisualization.js")
);
const FileHandlerComponent = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/download-and-printing/FileHandlerComponent.js")
);
const JSONDIFF = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/json-tools/JSONDiff.js")
);
const JSONClearValues = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/json-tools/JSONClear.js")
);
const JSONRemoveClearValues = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/json-tools/JSONRemoveClearValues.js")
);
const JSONKeyRemover = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/json-tools/JSONKeyRemover.js")
);
const JSONMerger = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/json-tools/JSONMerger.js")
);
const JSONSplitter = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/json-tools/JSONSplitter.js")
);
const JSONDataobfuscator = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/json-tools/JSONDataObfuscator.js")
);
const JSONTransformer = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/json-tools/JSONTransformer.js")
);

// CSS TOOLS
const CSSGridLayoutGenerator = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/css-tools/CSSGridLayoutGenerator.js")
);
const ViewHelp = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/Help")
);
const ViewContactUs = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/ContactUs")
);
const ViewAboutUs = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/AboutUs")
);
//!SECTION

// SECTION CUSTOMER OR WEB ROUTES
const ToolsDashboard = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/tools")
);
const SchemaGenerator = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/tools/SchemeGenerator")
);
const SchemaGeneratorFromData = lazy(() =>
  import(
    /* webpackChunkName: "views-app" */ "./view/tools/SchemeGenerator/FromData"
  )
);
const SchemaGeneratorFromExcel = lazy(() =>
  import(
    /* webpackChunkName: "views-app" */ "./view/tools/SchemeGenerator/FromExcel"
  )
); 
//!SECTION

// SECTION SUPERADMIN ROUTES
const ViewAdmin = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/admin/index")
);
const ViewAdminMedia = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/admin/media/index")
);
const MediaForm = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/admin/media/MediaForm")
);
const ViewAdminCategory = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/admin/category/index")
);
const CategoryForm = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/admin/category/CategoryForm")
);
const ViewAdminProduct = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/admin/product/index")
);
const ProductForm = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/admin/product/ProductForm")
);
// SECTION SUPERADMIN ROUTES
const ViewDoc = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/doc/index")
);
const ViewDocTable = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/doc/components/CustomAntiriseTable/index")
);

//!SECTION

// SECTION MENUS ROUTES
const Menu = [
  // NOTE WEB AND CUSTOMER MENU STARTS
  {
    element: <WebProtectedLayout />,
    path: "/",
    children: [
      {
        index: true,
        element: <ViewHome />,
      },
      // {
      //   path: "/data-visualization/data-visualization",
      //   element: <DataVisualization  />,
      // },
      // {
      //   path: "/download-and-print-tools/download-and-print",
      //   element: <FileHandlerComponent  />,
      // }, 
      // {
      //   path: "/json-tools/json-diff",
      //   element: <JSONDIFF  />,
      // },
      // {
      //   path: "/json-tools/json-clear-values",
      //   element: <JSONClearValues  />,
      // },
      // {
      //   path: "/json-tools/json-remove-empty-keys",
      //   element: <JSONRemoveClearValues  />,
      // },
      // {
      //   path: "/json-tools/json-keys-remover",
      //   element: <JSONKeyRemover  />,
      // },
      // {
      //   path: "/json-tools/json-merger",
      //   element: <JSONMerger  />,
      // },
      // {
      //   path: "/json-tools/json-splitter",
      //   element: <JSONSplitter  />,
      // },
      // {
      //   path: "/json-tools/json-data-obfuscator",
      //   element: <JSONDataobfuscator  />,
      // },
      // {
      //   path: "/json-tools/json-transformer",
      //   element: <JSONTransformer  />,
      // },
      // {
      //   path: "/css-tools/css-grid-layout-generator",
      //   element: <CSSGridLayoutGenerator  />,
      // },
      {
        path: ":category",
        element: <ProductList  />,
        children: [
          {
            path: ":product",
            element: <Product  />,
          },
        ],
      },
      {
        path: "/search",
        element: <ProductList  />,
        children: [
          {
            path: ":search_string",
            element: <ProductList  />,
          },
        ],
      },
      // NOTE OTHER MENU STARTS 
      {
        path: "/help",
        element: <ViewHelp />,
      },
      {
        path: "/about",
        element: <ViewAboutUs />,
      },
      {
        path: "/contact",
        element: <ViewContactUs />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/service-terms",
        element: <ViewContactUs />,
      },
      {
        path: "/*",
        element: <ViewNotFound />,
      },
      // {
      //   element: <ToolsDashboard />,
      //   path: "/tools",
      //   children: [
      //     {
      //       path: "/tools/schema-generator",
      //       element: <SchemaGenerator />,  
      //     }, 
      //     { 
      //       path: "/tools/schema-generator/schema-generator-from-data",
      //       element: <SchemaGeneratorFromData />,
      //       children: [
      //         { 
      //           path: ":id",
      //           element: <SchemaGeneratorFromData />,
      //         },
      //       ]
      //     },
      //     {
      //       path: "/tools/schema-generator/schema-generator-from-excel/:id",
      //       element: <SchemaGeneratorFromExcel/>,
      //     },
      //     // {
      //     //   path: ":teamId/edit",
      //     //   element: <EditTeam />,
      //     // },
      //     // {
      //     //   path: "new",
      //     //   element: <NewTeamForm />,
      //     // },
      //   ],
      // },
    ],
  },
  {
    element: <WIthoutHeaderFooterLayout />,
    // element: <WebProtectedLayout />,
    children: [
      {
        path: "/login",
        element: <ViewLogin />,
      },
      {
        path: "/register",
        element: <ViewRegister />,
      },
      {
        path: "/unauthorized",
        element: <ViewUnauthorized />,
      },
      {
        path: "/error",
        element: <ViewError />,
      },
    ],
  },
  // NOTE BUSINESS MENU STARTS
  {
    element: <AdminLayout sidebar={true} />,
    path: adminRoot,
    children: [
      {
        index: true,
        element: <ViewAdmin />,
      },
      {
        element: <ViewAdminMedia />,
        path: 'media',
        children: [
          {
            path: ":action",
            element: <MediaForm  />,
          },
        ],
      },
      {
        element: <ViewAdminCategory />,
        path: 'category',
        children: [
          {
            path: ":action",
            element: <CategoryForm />,
          },
        ],
      }, 
      {
        element: <ViewAdminProduct />,
        path: 'listing',
        children: [
          {
            path: ":action",
            element: <ProductForm />,
          },
        ],
      }, 
      {
        path: 'role',
        element: <ViewAdmin />,
      },
      // {
      //   element: <ToolsDashboard />,
      //   // index: true,
      //   path: "/tools",
      //   children: [
      //     {
      //       path: "/tools/schema-generator",
      //       element: <SchemaGenerator />,  
      //     }, 
      //     { 
      //       path: "/tools/schema-generator/schema-generator-from-data",
      //       element: <SchemaGeneratorFromData />,
      //       children: [
      //         { 
      //           path: ":id",
      //           element: <SchemaGeneratorFromData />,
      //         },
      //       ]
      //     },
      //     {
      //       path: "/tools/schema-generator/schema-generator-from-excel/:id",
      //       element: <SchemaGeneratorFromExcel/>,
      //     },
      //     // {
      //     //   path: ":teamId/edit",
      //     //   element: <EditTeam />,
      //     // },
      //     // {
      //     //   path: "new",
      //     //   element: <NewTeamForm />,
      //     // },
      //   ],
      // },
    ],
  },
  // NOTE BUSINESS MENU STARTS
  {
    element: <AdminLayout sidebar={true} doc={true} />,
    path: '/doc',
    children: [
      {
        index: true,
        element: <ViewDoc />,
      },
      {
        path: '/doc/component/table',
        element: <ViewDocTable />,
      },
    ],
  },
  {
    element: <WIthoutHeaderFooterLayout />,
    children: [
      {
        path: "/admin/login",
        element: <ViewLogin />,
      },
      {
        path: "/admin/register",
        element: <ViewRegister />,
      },
    ],
  },
  // NOTE SUPERADMIN MENU STARTS
  // {
  //   element: <WebProtectedLayout />,
  //   path: "/superadmin",
  //   children: [
  //     {
  //       index: true,
  //       element: <ViewHome />,
  //     },
  //     // {
  //     //     path: "login",
  //     //     element: <ViewLogin />,
  //     // }

  //     // {
  //     //   path: "teams",
  //     //   element: <Teams />,
  //     //   children: [
  //     //     {
  //     //       index: true,
  //     //       element: <LeagueStandings />,
  //     //     },
  //     //     {
  //     //       path: ":teamId",
  //     //       element: <Team />,
  //     //     },
  //     //     {
  //     //       path: ":teamId/edit",
  //     //       element: <EditTeam />,
  //     //     },
  //     //     {
  //     //       path: "new",
  //     //       element: <NewTeamForm />,
  //     //     },
  //     //   ],
  //     // },
  //   ],
  // },
  // {
  //   element: <WIthoutHeaderFooterLayout />,
  //   children: [
  //     {
  //       path: "/superadmin/login",
  //       element: <ViewLogin />,
  //     },
  //   ],
  // },
];
// !SECTION

export default Menu;
