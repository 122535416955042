const seoProps = {
   '/about': {
    title: 'My Page Title',
    description: 'This is a description of my page.',
    keywords: 'react, seo, example',
    canonical: 'https://example.com/my-page',
    image: 'https://example.com/image.jpg',
    alt: 'Description of the image',
   }
};

export default seoProps