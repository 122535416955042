// notificationSlice.js

import { createSlice } from '@reduxjs/toolkit';

let nextNotificationId = 0;

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: [],
  reducers: {
    addNotification: {
      reducer: (state, action) => {
        state.push(action.payload);
      },
      prepare: (status, title, message, duration = 3000) => {
        const id = nextNotificationId++;
        return {
          payload: {
            id,
            status,
            title,
            message,
            duration,
          },
        };
      },
    },
    removeNotification: (state, action) => {
      return state.filter(notification => notification.id !== action.payload);
    },
  },
});

export const { addNotification, removeNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
