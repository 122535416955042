import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "reactstrap";
import {
  addNotification,
  removeNotification,
} from "../../redux/notificationSlice";

const NotificationManager = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);

  const closeNotification = (id) => {
    dispatch(removeNotification(id));
  };

  // const createNotification = (status, title, message, duration = 3000) => {
  //   dispatch(addNotification(status, title, message, duration));
  //   setTimeout(() => {
  //     closeNotification(id);
  //   }, duration);
  // };

  return (
    <div className="notification-manager fixed-bottom end-0 w-300px ms-auto d-inline-block">
      <div className="d-flex flex-column align-items-end">
        {notifications.map((notification) => (
          <Alert
            key={notification.id}
            className={`m-1 alert alert-${notification.status}`}
          >
            <button
              className={`position-absolute top-0 end-0 p-1 btn text-${notification.status}`}
              onClick={() => closeNotification(notification.id)}
            >
              <i className="bi bi-x-lg"></i>
            </button>
            {notification.title ? (
              <h4 className="alert-heading">{notification.title}</h4>
            ) : null}
            {notification.message ? (
              <div style={{ minWidth: "250px" }}>{notification.message}</div>
            ) : null}
          </Alert>
        ))}
      </div>
    </div>
  );
};

export default NotificationManager;
