import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getRollandPermission, themeSettingStorageKey } from "../config/config"; 
import ProtectedRoute from "./ProtectedRoute";

const WIthoutHeaderFooterLayout = (props) => {
  // const location = useLocation();

  // if (getRollandPermission[role]["pageVisit"][location?.path]) {
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === themeSettingStorageKey) {
        window.location.reload();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

    return (
      <ProtectedRoute>
        {/* WITHOUT HEADER */}
        <main>
          <Outlet />
        </main>
        {/* WITHOUT FOOTER */}
      </ProtectedRoute>
    );
  // }
  return <Navigate to="/unauthorized" replace={true} />
};

export default WIthoutHeaderFooterLayout;
