import React from 'react'
import { Link } from 'react-router-dom'

const logo = ["Dev", "Tools"]

const Logo = ({color}) => {
  let mycolor = 'text-primary'
  if(color === 'fullWhite'){
    mycolor = 'text-light'
  }  
  return (
    <Link className={`${color?.includes('full') ? mycolor : 'text-dark'} navbar-brand fw-bold`} to="/">
    {/* <img
        className="img-fluid logo"
        src="assets/images/logo-colored.png"
        alt="logo"
    /> */}
    <span className={`${mycolor} fw-bold h1`}>Crafty{logo[0]}</span>{logo[1]}
    </Link>
  )
}

export default Logo