  export const productSchemaData = (tool) => tool ? {
      "@context": "http://schema.org",
      "@type": "SoftwareApplication",
      "name": tool.title,
      "description": tool.description,
      "url": window.location.origin + '/' + tool.slug_url,
      "applicationCategory": tool.category,
      "keywords": tool.tags.join(', '),
      "isAccessibleForFree": true,
      "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": "USD"
      },
      "operatingSystem": "All",
      "featureList": tool.features.map((feature) => ({
        "@type": "PropertyValue",
        "name": feature.value
      })),
      "useCaseList": tool.useCases.map((useCase) => ({
        "@type": "CreativeWork",
        "about": useCase.value
      })),
      "audience": tool.usedBy.map((userGroup) => ({
        "@type": "Audience",
        "audienceType": userGroup.value
      })),
  } : null;

  export const breadCrumbSchemaData = (tool) => {
    return {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": tool.map((segment, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "name": segment.label,
        "item": window.location.origin + segment.path
      }))
    };
  };   