import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl, themeUserStorageKey } from '../../config/config'
import { logout, setCredentials } from '../slices/authSlice';
import { getLocalStorage } from '../../helpers/Utils';
// import { handleResponse } from './responseHandler';

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // const token = getState().auth.token;
    const {token} = getLocalStorage(themeUserStorageKey);
    if (token) {
      headers.set('Authorization', token);
    }
    return headers;
  },
});

// const baseQueryWithInterceptor = async (args, api, extraOptions) => {
//   let response = await baseQuery(args, api, extraOptions);
//   response = await handleResponse(response);
//   return response;
// };

export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  // baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    // WEBSITE DETAILS
    fetchCurrencies: builder.query({
      query: () => '/website/currencies',
    }),
    fetchPeriods: builder.query({
      query: () => '/website/periodWords',
    }),
    websiteDetail: builder.mutation({
      query: ({ url }) => {
        return {
          url: '/website',
          method: 'POST',
          body: { url },
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
    }),
    // USERS AND ROLES
    loginUser: builder.mutation({
      query: (credentials) => ({
        url: '/user/login',
        method: 'POST',
        body: credentials,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setCredentials(data));
          }
        } catch (error) {
          // Handle error if needed
        }
      },
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: '/user/logout',
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(logout());
        } catch (error) {
          // Handle error if needed
        }
      },
    }),
    registerUser: builder.mutation({
      query: (user) => ({
        url: '/user/register',
        method: 'POST',
        body: user,
      }),
    }),
    fetchUserPermissions: builder.query({
      query: (userId) => `permissions/${userId}`,
    }),
    fetchUserList: builder.query({
      query: () => 'users',
    }),
    // MEDIA
    fetchMediaList: builder.query({
      query: () => '/media/',
    }),
    fetchMedia: builder.query({
      query: (id) => `/media/${id}`,
    }),
    createMedia: builder.mutation({
      query: (values) => {
         // Create a FormData object to handle multiple files
      const formData = new FormData();

      // Assuming `values.files` is an array of File objects
      // values.files.forEach((file, index) => {
      //   formData.append(`file_${index}`, file);
      // });

      // Append other form values if needed
      Object.keys(values).forEach((key) => {
        if (key !== "file") {
          formData.append(key, values[key]);
        }
        if (key === "file" && typeof values[key] === 'object') {
          formData.append(key, values[key]?.url ? null : values[key]);
          // formData.append(key, JSON.stringify(values[key]));
        }
      });
      return {
        url: `/media/single/${values.file.name.split('.').pop()}`,
        method: 'POST',
        body: formData,
      }
    },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            window.opener.postMessage({ status: 'success', data: data }, window.location.origin);
          }
        } catch (error) {
          // Handle error if needed
        }
      },
    }),
    deleteMedia: builder.mutation({
      query(id) {
        return {
          url: `/media/${id}`,
          method: 'DELETE',
        }
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: (result, error, id) => [{ type: 'Posts', id }],
    }),
    // --------------
    // CATEGORY ENDPOINTS
    // --------------
    fetchCategoryList: builder.query({
      query: () => '/category',
    }),
    fetchCategory: builder.query({
      query: (id) => `/category/${id}`,
    }),
    createCategory: builder.mutation({
      query: (values) => {
         // Create a FormData object to handle multiple files
      const formData = values;

      // Assuming `values.files` is an array of File objects
      // values.files.forEach((file, index) => {
      //   formData.append(`file_${index}`, file);
      // });

      // Append other form values if needed
      // Object.keys(values).forEach((key) => {
      //   if (key !== "file") {
      //     formData.append(key, values[key]);
      //   }
      //   if (key === "file" && typeof values[key] === 'object') {
      //     formData.append(key, values[key]?.url ? null : values[key]);
      //     // formData.append(key, JSON.stringify(values[key]));
      //   }
      // });
      return {
        url: `/category`,
        method: 'POST',
        body: formData,
      }
    },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setCredentials(data));
          }
        } catch (error) {
          // Handle error if needed
        }
      },
    }),
    deleteCategory: builder.mutation({
      query(id) {
        return {
          url: `/category/${id}`,
          method: 'DELETE',
        }
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: (result, error, id) => [{ type: 'Posts', id }],
    }),
    
    fetchActiveCategory: builder.query({
      query: (id) => `/category/active`,
    }),
    fetchIncreaseCategory: builder.query({
      query: (id) => `/category/count/increase/${id}`,
    }),

    // --------------
    // PRODUCT ENDPOINTS
    // --------------
    fetchProductList: builder.query({
      query: () => '/product/auth',
    }),
    fetchProduct: builder.query({
      query: (id) => `/product/productbyid/${id}`,
    }),
    createProduct: builder.mutation({
      query: (values) => {
        debugger
         // Create a FormData object to handle multiple files
      const formData = values;

      // Assuming `values.files` is an array of File objects
      // values.files.forEach((file, index) => {
      //   formData.append(`file_${index}`, file);
      // });

      // Append other form values if needed
      // Object.keys(values).forEach((key) => {
      //   if (key !== "file") {
      //     formData.append(key, values[key]);
      //   }
      //   if (key === "file" && typeof values[key] === 'object') {
      //     formData.append(key, values[key]?.url ? null : values[key]);
      //     // formData.append(key, JSON.stringify(values[key]));
      //   }
      // });
      return {
        url: `/product`,
        method: 'POST',
        body: formData,
      }
    },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setCredentials(data));
          }
        } catch (error) {
          // Handle error if needed
        }
      },
    }),
    deleteProduct: builder.mutation({
      query(id) {
        return {
          url: `/product/${id}`,
          method: 'DELETE',
        }
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: (result, error, id) => [{ type: 'Posts', id }],
    }),
    
    fetchProductBySlug: builder.query({
      query: (slug_url) => `/product/product/${slug_url}`,
    }),
    fetchLatestProduct: builder.query({
      query: () => `/product/latest`,
    }),
    fetchTrendingProduct: builder.query({
      query: () => `/product/trending`,
    }),
    fetchHighlightProduct: builder.query({
      query: () => `/product/highlight`,
    }),
    fetchShowcaseProduct: builder.query({
      query: () => `/product/showcase`,
    }),
    fetchLatestProductByCategoryId: builder.query({
      query: (categoryId) => `/product/latest/${categoryId}`,
    }),
    fetchRelatedProductBySlug: builder.query({
      query: (slug_url) => `/product/related/${slug_url}`,
    }),
    fetchProductsByCategorySlug: builder.query({
      query: (slug_url) => `/product/productbycat/${slug_url}`,
    }),
    fetchProductsByTag: builder.query({
      query: (tag) => `/product/productbytag/${tag}`,
    }),
    fetchIncreaseProduct: builder.query({
      query: (id) => `/product/count/increase/${id}`,
    }),

    // --------------
    // REVIEW ENDPOINTS
    // --------------

    fetchReviewList: builder.query({
      query: () => '/review/auth',
    }),
    fetchReview: builder.query({
      query: (id) => `/review/reviewbyid/${id}`,
    }),
    createReview: builder.mutation({
      query: (values) => {
        debugger
         // Create a FormData object to handle multiple files
      const formData = values;

      // Assuming `values.files` is an array of File objects
      // values.files.forEach((file, index) => {
      //   formData.append(`file_${index}`, file);
      // });

      // Append other form values if needed
      // Object.keys(values).forEach((key) => {
      //   if (key !== "file") {
      //     formData.append(key, values[key]);
      //   }
      //   if (key === "file" && typeof values[key] === 'object') {
      //     formData.append(key, values[key]?.url ? null : values[key]);
      //     // formData.append(key, JSON.stringify(values[key]));
      //   }
      // });
      return {
        url: `/review`,
        method: 'POST',
        body: formData,
      }
    },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setCredentials(data));
          }
        } catch (error) {
          // Handle error if needed
        }
      },
    }),
    deleteReview: builder.mutation({
      query(id) {
        return {
          url: `/review/${id}`,
          method: 'DELETE',
        }
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: (result, error, id) => [{ type: 'Posts', id }],
    }),

    fetchReviewsByProductId: builder.query({
      query: (id) => `/review/getReviewbyProduct/${id}`,
    }),

    // --------------
    // COLLECTION ENDPOINTS
    // --------------
    fetchCollectionList: builder.query({
      query: () => '/collection/auth',
    }),
    fetchCollection: builder.query({
      query: (id) => `/collection/collection/${id}`,
    }),
    createCollection: builder.mutation({
      query: (values) => {
         // Create a FormData object to handle multiple files
      const formData = values;
 
      return {
        url: `/collection`,
        method: 'POST',
        body: formData,
      }
    },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setCredentials(data));
          }
        } catch (error) {
          // Handle error if needed
        }
      },
    }),
    deleteCollection: builder.mutation({
      query(id) {
        return {
          url: `/collection/${id}`,
          method: 'DELETE',
        }
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: (result, error, id) => [{ type: 'Posts', id }],
    }),

    
  }),
});

export const { 
  useWebsiteDetailMutation, 
  useLoginUserMutation, 
  useRegisterUserMutation, 
  // MEDIA
  useFetchMediaListQuery, 
  useFetchMediaQuery,  
  useCreateMediaMutation,
  useDeleteMediaMutation,
  // CATEGORY
  useFetchCategoryListQuery, 
  useFetchCategoryQuery,  
  useCreateCategoryMutation,
  useDeleteCategoryMutation,

  useFetchActiveCategoryQuery,
  useFetchIncreaseCategoryQuery,

  // PRODUCT
  useFetchProductListQuery, 
  useFetchProductQuery,  
  useCreateProductMutation,
  useDeleteProductMutation,
  
  useFetchProductBySlugQuery,
  useFetchLatestProductQuery,
  useFetchTrendingProductQuery,
  useFetchHighlightProductQuery,
  useFetchShowcaseProductQuery,
  useFetchLatestProductByCategoryIdQuery,
  useFetchRelatedProductBySlugQuery,
  useFetchProductsByCategorySlugQuery,
  useFetchProductsByTagQuery,
  useFetchIncreaseProductQuery,
  
  // REVIEW
  useFetchReviewListQuery, 
  useFetchReviewQuery,  
  useCreateReviewMutation,
  useDeleteReviewMutation,
  useFetchReviewsByProductIdQuery,

  // COLLECTION
  useFetchCollectionListQuery, 
  useFetchCollectionQuery,  
  useCreateCollectionMutation,
  useDeleteCollectionMutation,


  // ----- WEBSITE


  useFetchCurrenciesQuery,
  useFetchPeriodsQuery
 } = api;