import { Component, lazy, useEffect } from "react";
// import logo from "./logo.svg";
// import "./App.scss";
import { customerRoot, themeSettingStorageKey } from "./config/config";
import { useNavigate, useRoutes } from "react-router-dom"; 
import Menu from "./Menu";

// function MatchPath({ path, Comp }) {
//   let match = useMatch(path);
//   return match ? <Comp {...match} /> : null;
// }

function App() {
  let element = useRoutes(Menu);
  return element;
}

export default App;
