import React from 'react'

const Loader = () => { 

  return (
      <div className='position-fixed w-100 vh-100 d-flex justify-content-center align-items-center'>
        <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      </div>
  )
}

export default Loader
