import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
  RedditShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  EmailIcon,
  RedditIcon,
  PinterestIcon,
} from "react-share";

const ShareButtons = ({toolName}) => {
  const shareUrl = window.location.href; // Get current page URL
  const title = `Check out this awesome ${toolName} Tool!`;

  return (
    <div className="row">
      <div className="col-auto pe-0">
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon size={40} round={true} />
        </FacebookShareButton>
      </div>
      <div className="col-auto pe-0">
        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={40} round={true} />
        </TwitterShareButton>
      </div>
      <div className="col-auto pe-0">
        <LinkedinShareButton url={shareUrl} title={title}>
          <LinkedinIcon size={40} round={true} />
        </LinkedinShareButton>
      </div>
      <div className="col-auto pe-0">
        <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
          <WhatsappIcon size={40} round={true} />
        </WhatsappShareButton>
      </div>
      <div className="col-auto pe-0">
        <TelegramShareButton url={shareUrl} title={title}>
          <TelegramIcon size={40} round={true} />
        </TelegramShareButton>
      </div>
      <div className="col-auto pe-0">
        <EmailShareButton url={shareUrl} subject={title} body="Check out this link: ">
          <EmailIcon size={40} round={true} />
        </EmailShareButton>
      </div>
      <div className="col-auto pe-0">
        <RedditShareButton url={shareUrl} title={title}>
          <RedditIcon size={40} round={true} />
        </RedditShareButton>
      </div>
      <div className="col-auto pe-0">
        <PinterestShareButton url={shareUrl} media={`${window.location.origin}/path-to-image`}>
          <PinterestIcon size={40} round={true} />
        </PinterestShareButton>
      </div>
    </div>
  );
};

export default ShareButtons;
