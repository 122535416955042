import React, { useEffect } from "react";
import { Outlet, useResolvedPath } from "react-router-dom";
import WebHeader from "./WebHeader";
import WebFooter from "./WebFooter";
import { themeSettingStorageKey } from "../config/config";
import useSEO from "./useSEO";
import seoProps from "../data/seo";

const WebProtectedLayout = (props) => {
  const prams = useResolvedPath()

  useSEO(seoProps[prams.pathname] || '')


  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === themeSettingStorageKey) {
        window.location.reload();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <>
      <WebHeader {...props}></WebHeader>
      <main><Outlet /></main>
      <WebFooter {...props}></WebFooter>
      {/* <h1>FOOTER LOGINED FOR WEB AND CUSTOMER</h1> */}
    </>
  );
};

export default WebProtectedLayout;
