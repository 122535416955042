import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import { localUser, themeSettingStorageKey, themeUserStorageKey } from "./config/config";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import Loader from "./layout/Loader";
import NotificationManager from "./components/toast-notifications/NotificationManager";
import useLocalStorage from "./hooks/UseLocalStorage";
import { getLocalStorage, setLocalStorage } from "./helpers/Utils";


// useEffect(() => {
  // const storedTheme = localStorage.getItem('theme')

  // const getPreferredTheme = () => {
  //   if (storedTheme) {
  //     return storedTheme
  //   }

  //   return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  // }

  // const setTheme = function (theme) {
  //   if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //     document.documentElement.setAttribute('data-bs-theme', 'dark')
  //   } else {
  //     document.documentElement.setAttribute('data-bs-theme', theme)
  //   }
  // }

  // setTheme(getPreferredTheme())

  // const showActiveTheme = theme => {
  //   const activeThemeIcon = document.querySelector('.theme-icon-active use')
  //   const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`)
  //   const svgOfActiveBtn = btnToActive.querySelector('svg use').getAttribute('href')

  //   document.querySelectorAll('[data-bs-theme-value]').forEach(element => {
  //     element.classList.remove('active')
  //   })

  //   btnToActive.classList.add('active')
  //   activeThemeIcon.setAttribute('href', svgOfActiveBtn)
  // }

  // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
  //   if (storedTheme !== 'light' || storedTheme !== 'dark') {
  //     setTheme(getPreferredTheme())
  //   }
  // })

  // window.addEventListener('load', () => {
  //   showActiveTheme(getPreferredTheme())

  //   document.querySelectorAll('[data-bs-theme-value]')
  //     .forEach(toggle => {
  //       toggle.addEventListener('click', () => {
  //         const theme = toggle.getAttribute('data-bs-theme-value')
  //         localStorage.setItem('theme', theme)
  //         setTheme(theme)
  //         showActiveTheme(theme)
  //       })
  //     })
  // })
// }, [])
const getUser = getLocalStorage(themeUserStorageKey);
const {isDarkSwitchActive, defaultColor} = getLocalStorage(themeSettingStorageKey);
if(!getUser?.userType?.userType) setLocalStorage(themeSettingStorageKey, localUser[themeSettingStorageKey]);
if(!isDarkSwitchActive) setLocalStorage(themeUserStorageKey, localUser[themeUserStorageKey]);

function Theme() {
  import(
    `./assets/scss/theme/${
      isDarkSwitchActive ? `dark` : `light`
    }.${defaultColor || 'golden'}.scss`
  ).then(() => {
    require(root.render(
      <React.StrictMode>
        <Suspense fallback={<Loader/>}>
        <Provider store={store}>
          <NotificationManager />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
        </Suspense>
      </React.StrictMode>
    ));
  });
}
Theme();

const root = ReactDOM.createRoot(document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
