/* eslint-disable no-eval */
import { localUser, prifix, themeUserCollectionKey, themeUserStorageKey } from "../config/config";
import * as XLSX from 'xlsx';

export const getValueByNestedName = (obj, propName) => {
  console.log("obj, propName: ", obj, propName);

  const propParts = propName.split(".");
  const value = propParts.reduce((obj, key) => obj && obj[key], obj);

  return value;

  // const propNames = propName.split('.');
  // let value = obj;

  // for (let i = 0; i < propNames.length; i++) {
  //   value = value[propNames[i]];
  //   if (value === undefined) {
  //     return undefined;
  //   }
  // }

  // return value;
};

export function replaceWithLength(obj) {
  const result = {};
  for (let prop in obj) {
    if (typeof obj[prop] === "string") {
      result[prop] = obj[prop].length;
    } else if (typeof obj[prop] === "object") {
      result[prop] = replaceWithLength(obj[prop]);
    } else {
      result[prop] = obj[prop];
    }
  }
  return result;
}

export function trimNestedObject(obj) {
  const result = {};
  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      result[key] = value.map((item) =>
        typeof item === "object" ? trimNestedObject(item) : item?.trim()
      );
    } else if (typeof value === "object" && value !== null) {
      result[key] = trimNestedObject(value);
    } else if (typeof value === "string") {
      result[key] = value.trim();
    } else {
      result[key] = value;
    }
  }
  return result;
}

// export function updateObjectData(obj, data) {
//   for (let key in obj) {
//     console.log("obj, data: ", key, obj, data);
//     let value = obj[key];
//     if (Array.isArray(value) && data[key]?.length > 0) {
//       let arrayType = [];
//       if (JSON.stringify(value) === '[""]' || JSON.stringify(value) === "[',']") {
//         arrayType = data[key].split(",");
//       } else  if(typeof value[0] === "string") {
//         arrayType = value;
//       }else{
//         arrayType = data[key];
//       }

//       obj[key] = value[0] ? arrayType.map(c => c && value[0]) : obj[key]
//       arrayType.forEach((item, index) => {
//         if (typeof item === "object" && item) {
//           // recursively call replaceValues for nested objects
//           console.log('value[0]: ', value[0], value[index], item);
//           value[index] = updateObjectData(value[index], item);
//         } else if (
//           typeof value[index] === "string" &&
//           value[index].includes("${")
//         ) {
//           // use eval to replace values for string properties with '${}' placeholders
//           let evalString = "`" + value[index] + "`";
//           let evaluatedValue = eval(evalString);
//           if (evaluatedValue.length > 0) {
//             // value[index] = evaluatedValue;
//           }
//         } else {
//           console.log("item: ", item);
//           // value[index] = item || value[index];
//         }
//       });
//     } else if (typeof value === "object" && data[key]) {
//       // recursively call replaceValues for nested objects
//       obj[key] = updateObjectData(value, data[key]);
//     } else if (typeof value === "string" && value.includes("${")) {
//       // use eval to replace values for string properties with '${}' placeholders
//       let evalString = "`" + value + "`";
//       let evaluatedValue = eval(evalString);
//       if (evaluatedValue.length > 0) {
//         obj[key] = evaluatedValue;
//       }
//     } else {
//       console.log("data[key]: ", data[key]);
//       obj[key] = data[key] || value;
//     }
//   }
//   return obj;
// }

export function updateObjectData(obj, data, index, allData) {
  console.log('data: ',obj, data);
  const updatedObj = {};

  for (let key in obj) {
    let value = obj[key];

    if (Array.isArray(value) && data[key]?.length > 0) {
      let arrayType = [];
      if (
        JSON.stringify(value) === '[""]' ||
        JSON.stringify(value) === "[',']"
      ) {
        arrayType = data[key].split(",");
      } else if (typeof value[0] === "string") {
        arrayType = value;
      } else {
        arrayType = data[key];
      }

      updatedObj[key] = arrayType.map((item, index) => {
        if (typeof item === "object" && item !== null) {
          return updateObjectData(value[0], item, index, allData);
        } else if (typeof item === "string" && item.includes("${")) {
          let evalString = "`" + item + "`";
          return eval(evalString);
        } else {
          return item;
        }
      });
    } else if (typeof value === "object" && value !== null && data[key]) {
      updatedObj[key] = updateObjectData(value, data[key], index, allData);
    } else if (typeof value === "string" && value.includes("${")) {
      // use eval to replace values for string properties with '${}' placeholders
      let evalString = "`" + value + "`";
      let evaluatedValue = eval(evalString);
      if (evaluatedValue.length > 0) {
        updatedObj[key] = evaluatedValue;
      }
    } else {
      updatedObj[key] = data[key] || value || "";
    }
  }
  return updatedObj;
}

export function createSchemaJson(schemaFeatures, json) {
  const schema = json;
  schemaFeatures?.forEach(element => {
    if(element.pushInkey){
      schema[element.pushInkey]?.push(schema[element.name])
    }
    if(element.pushInkey && element.pushBefore){
      schema[element.pushInkey]?.shift(schema[element.name]) 
    }
    if(element.deleteThisKey){
      delete schema[element.name]
    }
  }); 
  return schema;
}

// export function evaluateExpression(expression) {
//   try {
//     const func = new Function(`return ${expression}`);
//     return func();
//   } catch (error) {
//     console.error("Error evaluating expression:", error);
//     return null;
//   }
// }

export function evaluateExpression(expression) {
  try {
    console.log('expression: ', eval("`" + expression + "`"));
    return eval("`" + expression + "`");
  } catch (error) {
    console.error("Error evaluating expression:", error);
    return null;
  }
}

export const runHTMLInBrowser = (targetId, html) => {
  const targetElement = document.getElementById(targetId);
  if (!targetElement) {
    console.error(`Element with ID "${targetId}" not found.`);
    return;
  }

  targetElement.innerHTML = html;
};

export function capitalizeFirstLetterOfEachWord(str) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const getKeyValueinObj = (obj, keyPath) => {
  const keys = keyPath.split('.');
  let result = obj;

  for (const key of keys) {
    if (result[key] !== undefined) {
      result = result[key];
    } else {
      return undefined; // Return undefined if the key path is not valid
    }
  }

  return result;
};

export const setKeyValueInObject = (obj, keyPath, value) => {
  const keys = keyPath.split('.');
  let current = obj;

  const removeFalseKeys = (obj) => {
    if (typeof obj !== 'object') return obj;
    for (let key in obj) {
      if (obj[key] === false) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        obj[key] = removeFalseKeys(obj[key]);
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
    }
    return obj;
  };

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (i === keys.length - 1) {
      if (value === false) {
        // Remove key if value is false
        delete current[key];
      } else {
        current[key] = value;
      }
    } else {
      if (!current[key] || typeof current[key] !== 'object') {
        current[key] = {};
      }
      current = current[key];
    }
  }

  return removeFalseKeys(obj);
};

// LOCALSTORAGE

export const setLocalStorage = (key, userobject) => {
  console.log("🚀 ~ setLocalStorage ~ key, userobject:", key, userobject)
  try {
    if(userobject){
      localStorage.setItem(
        key,
        JSON.stringify(userobject)
      );
    }else{
      localStorage.removeItem(
        key
      );
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setLocalStorage -> error", error);
  }
};

export const getLocalStorage = (key) => {
  let currentUser = {};
  try {
    if (localStorage.getItem(key)) {
      currentUser = JSON.parse(
        localStorage.getItem(key)
      );
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getLocalStorage -> error", error);
  }
  return currentUser;
};

export const handelSetCurrentAction = ({setCurrentAction, action}) => {
  setCurrentAction(action)
}

export function getUrlExcludingHost(fullUrl) {
  const url = new URL(fullUrl);
  return url.pathname + url.search + url.hash;
}

// utils.js
export const openNewPage = ({ pageLinkFrom, pageLinkTo, refetch, setAddedResponse }) => {
  const newWindow = window.open(pageLinkTo, '_blank');

  const messageHandler = (event) => {
    if (event.origin !== window.location.origin) {
      // Ignore messages from other origins
      return;
    }

    const { status, data } = event.data;

    if (status === 'success') {
      if (typeof setAddedResponse === 'function') {
        setAddedResponse(data);
      }
      if (typeof refetch === 'function') {
        refetch();
      }
      newWindow.close();
      window.removeEventListener('message', messageHandler);
    }
  };

  window.addEventListener('message', messageHandler);
};

export const getColonHeadingAndText = (value) => (
  value?.indexOf(":") > 0 ? <><b>{value?.split(':')[0]} :</b>{value?.split(':')[1]}</> : value
)


// Example usage after login to sync the unsynced collection
// export const syncUnsyncedCollection = async () => {
//   const unsyncedCollection = localStorage.getItem('unsyncedCollection');
//   if (unsyncedCollection) {
//     const collectionData = JSON.parse(unsyncedCollection);
//     await handleSubmitCollection(collectionData);
//   }
// };

// export const handleSubmitCollection = async (e) => {
//   try {
//     const reqObj = {
//       product: e.productId,  // Assuming product IDs are passed in an array
//       title: e.collectionName,
//     };
//     const getCollections = getLocalStorage(themeUserCollectionKey) || [];

//     // add product to the collection which collection name matched
//     const getCollectionsUpdated = getCollections?.map(d => d.title === reqObj.title ? ({...d, title: reqObj.title, product: [...d.product, reqObj.product]}) : d)

//     const getUser = getLocalStorage(themeUserStorageKey);
//     const token = getUser?.token || ''

//     if (!token) {
//       // Store collection data in local storage
//       setLocalStorage(themeUserCollectionKey, getCollectionsUpdated);
//     } else {
//       setLocalStorage(themeUserCollectionKey, getCollectionsUpdated);

//       // Sync collection if user is logged in
//       // const { data } = await syncCollection(reqObj);
//       // dispatch(
//       //   addNotification(
//       //     data?.success ? "success" : "danger",
//       //     "",
//       //     data?.msg || "Your Collection Synced Successfully"
//       //   )
//       // );
//       // if (data?.success) {
//       //   localStorage.removeItem('unsyncedCollection'); // Remove from local storage after sync
//       // }
//     }
//   } catch (error) {
//     console.error("Error syncing collection: ", error);
//   }
// };


export const handleFileUpload = (event, options, handleFileContent) => {
  const { jsonFile, textFile, excelFile } = options;
  const fileReader = new FileReader();
  const file = event.target.files[0];

  fileReader.onload = (e) => {
    try {
      let result;
      const fileContent = e.target.result;

      if (jsonFile) {
        // Handle JSON file
        try {
          const json = fileContent;
          result = json;
        } catch (error) {
          return alert('Invalid JSON file. Please upload a valid JSON.');
        }
      } else if (textFile) {
        // Handle plain text file
        result = fileContent;
      } else if (excelFile) {
        // Handle Excel file
        try {
          const workbook = XLSX.read(fileContent, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonArray = XLSX.utils.sheet_to_json(sheet);
          result = jsonArray;
        } catch (error) {
          return alert('Invalid Excel or CSV file. Please upload a valid Excel or CSV.');
        }
      } else {
        return alert('Unsupported file type or file.');
      }

      // Pass the result to the handler
      handleFileContent(result);

    } catch (error) {
      alert('An error occurred while processing the file. Please try again.');
    }
  };

  if (file) {
    if (jsonFile || textFile) {
      fileReader.readAsText(file);
    } else if (excelFile) {
      fileReader.readAsBinaryString(file);
    } else {
      alert('Unsupported file format.');
    }
  } else {
    alert('No file selected.');
  }
};




