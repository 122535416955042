
// ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom'
import WebHeader from './WebHeader';
import WebFooter from './WebFooter';
import ProtectedRoute from './ProtectedRoute'; 
import { sidebarWidth, themeSidebarStorageKey} from '../config/config';
import useLocalStorage from '../hooks/UseLocalStorage';
import { getLocalStorage } from '../helpers/Utils';
import BreadCrumb from './BreadCrumb';

const AdminLayout = (props) => { 
  return (
    <ProtectedRoute>
      <WebHeader {...props}></WebHeader>
      <main><Outlet /></main>
    </ProtectedRoute>
  )
}

export default AdminLayout
