import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { needToSaveProduct } from "../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { getLocalStorage } from "../helpers/Utils";
import { themeUserCollectionKey } from "../config/config";


export const handleSubmitCollection = async (e, getProductBySlugData, dispatch) => {
  if(getProductBySlugData?._id){
    dispatch(needToSaveProduct(getProductBySlugData))
  }
};

export const HeartButton = ({getProductBySlugData}) => {
  const getProductBySlugDataEdited = getProductBySlugData

  const dispatch = useDispatch()
  
  // const [collections, setCollections] = useLocalStorage(themeUserCollectionKey, getLocalStorage(themeUserCollectionKey)|| [])
  const [collections, setCollections] = useState(getLocalStorage(themeUserCollectionKey)|| [])
	console.log("TCL: collections", collections)
  const getCollectionsWithoutFilter = Array.isArray(collections) ? collections?.filter(d => d) : []
  const collectionMatched = getCollectionsWithoutFilter?.filter(d => d.product?.some(c => c?._id?.toLowerCase() === getProductBySlugData?._id?.toLowerCase()))
  const getCollectionsMatched = [
    ...getCollectionsWithoutFilter.filter(d => collectionMatched.some(c => c.title === d.title))?.map(c => ({...c, matched : true})),
    ...getCollectionsWithoutFilter.filter(d => !(collectionMatched.some(c => c.title === d.title)))
  ]
  console.log("TCL: HeartButton -> collectionMatched", getCollectionsMatched, collectionMatched, getProductBySlugDataEdited)
  
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === themeUserCollectionKey) {
        setCollections(getLocalStorage(themeUserCollectionKey) || [])
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return ("")
  //  (
  //   <button type="button" onClick={(e) => handleSubmitCollection(e, getProductBySlugDataEdited, dispatch)} className="ms-auto px-3 btn d-flex align-items-center">
  //     <span className="h5"> Save </span>{" "}
  //     <span className={`ms-2 mb-2 ${getCollectionsMatched?.some(d => d.matched === true) ? 'text-primary' : ''}`}>
  //       <i className={`fs-4 bi ${getCollectionsMatched?.some(d => d.matched === true) ? 'bi-heart-fill' : 'bi-heart'}`}></i>
  //     </span>
  //   </button>
  // )
}

export const CreateIframeAsImage = ({url}) => {
  const iframeHolder = useRef(null);
  const [scale, setScale] = useState(1);
  
  useEffect(() => {
    if (iframeHolder.current) {
      const width = iframeHolder.current?.clientWidth;
      const height = iframeHolder.current?.clientHeight;
      const widthIframe = iframeHolder.current?.getElementsByTagName('iframe')[0]?.clientHeight;
      const heightIframe = iframeHolder.current?.getElementsByTagName('iframe')[0]?.clientHeight;
      // const calculatedScale = Number(((width / height)) / 10);
      // const calculatedScale = Number((width / (widthIframe)));
      const calculatedScale = Number((height / (heightIframe)));
      // const calculatedScale = Number((width / height) / 10);
      setScale(calculatedScale);
      console.log("🚀 ~ CreateIframeAsImage ~ iframeHolder:", width, height, widthIframe, heightIframe, scale)
    }
  }, [iframeHolder.current]);
  
  return (
    <div className="responsive-iframe-container img-fluid w-100 aspect-6-1" ref={iframeHolder}>
      <iframe
        src={url}
        style={{
          width: '1440px',
          scale: scale?.toFixed(2)
          // scale: '0.19'
        }}
        className="aspect-6-1 hide-scroll"
      />
    </div>
  )
}

export const CardComponent = ({cardList, dispatch}) => 
    cardList?.map(card => (
      <div key={card._id} className="col d-flex">
        <div className="card border-primary shadow shadow-primary w-100">
          <div className="card-body">
            {!card.hideImage ? <div className="img-holder bg-img-holder d-block  position-relative">
              {card.image ? (
                <img
                  className="img-fluid w-100 aspect-6-1"
                  src={card.image?.path}
                  alt={card.title}
                />
              ) : (
                <CreateIframeAsImage {...card} />
              )}
              <Link to={`${card.slug_url.toString().toLowerCase()}`} className="overlay"></Link>
              <span className="badge position-absolute top-0 mt-1 end-0 p-2 rounded rounded-end-0 text-bg-light-primary shadow">
                🔥 Featured
              </span>
            </div> : null}
            <div className="d-flex align-items-center">
              <h3 className="card-title fs-5 pt-3">
                <Link to={`${card.slug_url.toString().toLowerCase()}`}>{card.title}</Link>
              </h3>
              <HeartButton getProductBySlugData={card}/>
            </div>
            <div className="pb-2">
              <p className="card-text">
                {card.short_description || ''}
              </p>
            </div>
          </div>
          <div className="card-footer border-0">
            <div className="row">
              <div className="col">
                <div className="row g-1">
                  {card.tags.slice(0,3)?.map(d => (
                    <div className="col-auto"><Link to={`/search?search=${d}`} className="badge text-dark bg-light-primary m-1">{d || ''}</Link></div>
                  ))}
                </div>
              </div>
              <div className="col-auto">
                <Link className="btn btn-text-light p-0" to={card.url} target="_blank">
                  Open Tool <i className="bi ms-2 bi-box-arrow-up-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
;


export const CardInlineImageComponent = ({cardList, hideFullGoToWebsite, showPricing, dispatch}) => 
  cardList?.map(card => (
    <div key={card._id} className="col d-flex">
      <div className={`card shadow shadow-primary w-100 border border-gray-600 d-flex flex-row`}>
        <div className="card-body position-relative bg-lighter-primary rounded">
          <div className="row h-100">
            <div className="col-auto position-relative d-flex">
              <div className="img-holder bg-img-holder d-block position-relative">
                {card.image ? (
                  <img
                    className="img-fluid w-200px aspect-6-1"
                    src={card.image?.path}
                    alt={card.title}
                  />
                ) : (
                  <div className="w-200px">
                    <CreateIframeAsImage {...card} />
                  </div>
                )}
                <Link to={`${card.slug_url.toString().toLowerCase()}`} className="overlay"></Link>
                <span className="badge position-absolute top-0 mt-1 end-0 p-2 rounded rounded-end-0 text-bg-light-primary shadow">
                  🔥 Featured
                </span>
              </div>
            </div>
            <div className="col h-100">
              <div className="row flex-column h-100 justify-content-around">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <h3 className="card-title fs-5">
                      <Link to={`${card.slug_url.toString().toLowerCase()}`}>{card.title}</Link>
                      {hideFullGoToWebsite ?
                      <Link className="btn btn-text-light p-0" to={card.url} target="_blank">
                        <i className="bi ms-2 bi-box-arrow-up-right"></i>
                      </Link> : null}
                    </h3>
                    <HeartButton getProductBySlugData={card}/>
                  </div>
                  <div className="pb-2">
                    <p className="card-text">
                      {card.short_description || ''}
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col">
                      <div className="row g-1">
                        {card.tags.slice(0,3)?.map(d => (
                          <div className="col-auto"><Link to={`/search?search=${d}`} className="badge text-dark bg-light-primary m-1">{d || ''}</Link></div>
                        ))}
                      </div>
                    </div>
                    {!hideFullGoToWebsite ? <div className="col-auto">
                      <Link className="btn btn-text-light p-0" to={card.url} target="_blank">
                        Open Tool <i className="bi ms-2 bi-box-arrow-up-right"></i>
                      </Link>
                    </div> : null}
                    {showPricing ? <div className="col-auto">
                      {card?.prices?.slice(0,3)?.map(d => (<a className="alert alert-primary py-0 px-1 me-1">{d.name || ''}</a>))}
                    </div> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
;

export const CardInlineIconImageComponent = ({cardList, hideFullGoToWebsite, showPricing}) => 
  cardList?.map(card => (
    <div key={card._id} className="col d-flex">
      <div className={`card shadow shadow-primary w-100 border border-gray-600 d-flex flex-row`}>
        <div className="card-body py-2 position-relative">
          <div className="row h-100">
            <div className="col-auto position-relative d-flex">
              <Link to={`${card.slug_url.toString().toLowerCase()}`} className="img-holder d-block">
                {card.iconUrl ? (
                  <img
                    className="img-fluid w-30px aspect-1-1"
                    src={card.iconUrl}
                    alt={card.title}
                  />
                ) : null}
              </Link> 
            </div>
            <div className="col h-100">
              <div className="row flex-column h-100 justify-content-around">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <h3 className="card-title fs-5 mb-0">
                      <Link to={`${card.slug_url.toString().toLowerCase()}`}>{card.title}</Link>
                    </h3>
                    {hideFullGoToWebsite ? <div className="ms-auto">
                      <Link className="btn btn-text-light p-0" to={card.url} target="_blank">
                        <i className="bi ms-2 bi-box-arrow-up-right"></i>
                      </Link>
                    </div> : null}
                  </div> 
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
;

const TabsAndCards = ({ tabList, cardList, setCurrentTab, currentTab, dispatch }) => {
  console.log("🚀 ~ TabsAndCards ~ cardList:", cardList)
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                {tabList.map((d) => (
                  <>
                    <div className="col-auto">
                      <button
                        type="button"
                        className="btn btn-outline-deep-primary"
                        onClick={()=> setCurrentTab(d.tabId)}
                        {...d}
                      >
                        {d.label}
                      </button>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>
          <div className="row gy-5">
            <div className="col-12">
              <h2 className="h1 mb-4">{tabList.find(d=> d.tabId=== currentTab).label}</h2>
              <div className="row row-cols-md-3 row-cols-sm-6 row-cols-1 gy-4">
                <CardComponent cardList={cardList} dispatch={dispatch}/>
              </div>
            </div>
          </div>    
        </div>
      </section>
    </>
  );
};

export default TabsAndCards;
