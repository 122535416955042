import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarToggler,
  UncontrolledDropdown,
} from "reactstrap";
import {
  SVGBell,
  SVGCart,
  SVGLogin,
  SVGMapMarker,
  SVGPerson,
  SVGSearch,
} from "../assets/icons/icons";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Logo from "./Logo";
import useLocalStorage from "../hooks/UseLocalStorage";
import { themeSettingStorageKey, themeSidebarStorageKey, themeUserCollectionKey, themeUserStorageKey } from "../config/config";
import { getLocalStorage, setLocalStorage } from "../helpers/Utils";
import CartSidebar from "./CartSidebar";
import { needToSaveProduct } from "../redux/slices/authSlice";
import { useCreateCollectionMutation } from "../redux/api/endpoints";
import RecentlyViewed from "./RecentlyViewed";

function WebHeader({ sidebar }) {
  const dispatch = useDispatch()
  const { getMenu } = useSelector((state) => state);
  const getNeedToSaveProduct = useSelector((state) =>
    state.auth?.needToSaveProduct
  );
  const [createCollection, { isLoading: createCollectionLoading }] = useCreateCollectionMutation();

  const [collection, setCollections] = useState(getLocalStorage(themeUserCollectionKey) || [])
  // const [collection, setCollections] = useLocalStorage(themeUserCollectionKey, getLocalStorage(themeUserCollectionKey) || [])
  const [currentUser, setCurrentUser] = useLocalStorage(themeUserStorageKey, getLocalStorage(themeUserStorageKey))
  const [themeSetting, setThemeSetting] = useLocalStorage(themeSettingStorageKey,  getLocalStorage(themeSettingStorageKey))
  const [collections] = useLocalStorage(themeUserCollectionKey, getLocalStorage(themeUserCollectionKey) || [])
  const getCollections = Array.isArray(collections) ? collections?.filter(d => d) : []
  const [showSidebar, setShowSidebar] = useState(false)
  const [showCart, setShowCart] = useState(false)
  const [showRecentlyViewed, setShowRecentlyViewed] = useState(false)
  const {userType} = currentUser;
  // specified the navbar of the page
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  
  // useEffect(() => {
  //   debugger
  //   if(getNeedToSaveProduct?.length > 0){
  //     setShowCart(true)
  //   }
  // }, [getNeedToSaveProduct]);
  const toggleNavbar = () => setShowSidebar(!showSidebar);

  const syncCollections = () => {
    if(typeof collection !== 'object' && collection?.filter(d => d)?.length > 0){
      const mySyncedCollection = collection?.map(async localCollection => {
        if(!localCollection.id){
          const response = await createCollection(localCollection);
          return response.data
        }
      })
      setCollections(mySyncedCollection?.filter(d => d));
    }
  }

  // useEffect(() => {
  //   if(currentUser.token){
  //     syncCollections()
  //   }
  // }, [currentUser.token]);

  const handleSwitchColorMode = () => {
    const mythemeSetting = themeSetting
    mythemeSetting.isDarkSwitchActive = !themeSetting?.isDarkSwitchActive
    setLocalStorage(themeSettingStorageKey, mythemeSetting)
    window.location.reload();
  };

  return (
    <>
      <div className={`site-header sticky-top shadow-sm`}>
        <Navbar
          // collapseOnSelect
          expand={!sidebar ? "lg" : false}
          variant="light"
          className="py-0"
        >
          <div className="row vw-100 align-items-center">
            <div className={`${!sidebar ? "order-lg-1 order-2" : 'order-2'} col-auto py-1 sidebar-width px-4 py-3`}>
              <Logo/>
            </div>
            {/* Every navbar has their own page which is specify through a link  */}

            <div className={`${!sidebar ? "order-lg-3 order-3" : 'order-3'} col`}>
              <ul className="nav nav-btns justify-content-end">
                {/* <li className="nav-item d-sm-flex align-items-center px-1">
                  <InputGroup className="input-group-absolute left">
                    <Input id="exampleSelect" name="select" type="select">
                      <option>Service Type</option>
                      <option>At Home</option>
                      <option>At Service Center</option>
                      <option>Both</option>\\\\\

                    </Input>  
                    <Button>
                      <i className="bi bi-geo-alt"></i>
                    </Button>
                  </InputGroup>
                </li> */}
                {/* <li className="nav-item d-sm-flex align-items-center col-md-auto col location px-1">
                  <InputGroup className="input-group-absolute left">
                    <Input
                      placeholder="Location"
                      value={"dwarka, delhi"}
                      readOnly
                    />
                    <div className="btn px-0 w-50px text-primary left ">
                      <SVGMapMarker />
                    </div>
                  </InputGroup>
                </li> */}
                {/* <li className="nav-item d-md-flex d-none align-items-center px-1 col-md-5 col-auto max-auto">
                  <InputGroup className="input-group-absolute right">
                    <Input className="border border-primary rounded" placeholder="How can we help you" />
                    <Button className="right btn-primary h-100">
                      <i className="bi bi-search"></i>
                    </Button>
                  </InputGroup>
                </li> */}
                <li className="nav-item align-items-center">
                  <Link to="/search" className="nav-link btn-icon rounded-pill" aria-label="Search Tool">
                    <div className="text-primary">
                      <i className="bi bi-search"></i>
                    </div>
                    <div className="text">Search</div>
                  </Link>
                </li>
                {/* <li className="nav-item d-sm-flex align-items-center px-1 col">
                  <InputGroup className="input-group-absolute right">
                    <Input />
                    <Button className="right">
                      <i className="bi bi-geo-alt"></i>
                    </Button>
                  </InputGroup>
                </li> */}
                {/* <li className="nav-item d-md-flex d-none align-items-center">
                  <a
                    to="cart.html"
                    className="nav-link  btn-icon btn-primary-gradient rounded-pill"
                  >
                    <div className="svgicon-holder mb-1 text-primary">
                      <SVGBell />
                    </div>
                    <div className="text">Alerts</div>
                  </a>
                </li> */}
                <li className="nav-item d-xs-flex d-none align-items-center">
                  <button
                    className="nav-link bg-none border-0 btn-icon rounded-pill"
                    onClick={handleSwitchColorMode}
                    aria-label="Change Theme Color"
                  >
                    <div className="svgicon-holder mb-1 text-primary">
                      {themeSetting?.isDarkSwitchActive ? (
                        <i className="bi bi-moon"></i>
                      ) : (
                        <i className="bi bi-sun"></i>
                      )}
                    </div>
                    <div className="text">
                      {themeSetting?.isDarkSwitchActive ? "Dark" : "Light"}
                    </div>
                  </button>
                </li>
                {/* {!userType || userType === "USER" ? (
                  <>
                    <li className="nav-item d-sm-flex align-items-center">
                      <Link
                        to="/login"
                        className="nav-link  btn-icon btn-primary-gradient rounded-pill"
                      >
                        <div className="svgicon-holder mb-1 text-primary">
                          <SVGPerson />
                        </div>
                        <div className="text">LogIn</div>
                      </Link>
                    </li>
                  </>
                ) : null} */}
                {/* <li className="nav-item d-sm-flex align-items-center d-none">
                  <button onClick={() => setShowCart(!showCart)} className="nav-link btn p-0 btn-icon">
                    <div className="svgicon-holder mb-1 text-primary position-relative">
                      <i class="bi bi-heart"></i>
                      <span
                        class="position-absolute top-0 st art-100 size-20px p-0 translate-middle bg-primary text-dark d-flex align-items-center justify-content-center border border-light rounded-circle"
                      >
                        <span>{getCollections?.length}</span><span class="visually-hidden">New alerts</span>
                      </span>
                    </div>
                    <div className="text">Saved</div>
                  </button>                  
                </li> */}
                <li className="nav-item d-sm-flex align-items-center d-none">
                  <button onClick={() => setShowRecentlyViewed(!showRecentlyViewed)} className="nav-link btn p-0 btn-icon fw-normal">
                    <div className="svgicon-holder mb-1 text-primary position-relative">
                      <div className="size-25px d-flex justify-content-center align-items-center rounded-circle border-aye-dashed border-primary">
                      <i class="bi bi-eye"></i>
                      </div>
                      {/* <span
                        class="position-absolute top-0 start-100 size-20px p-0 bg-primary text-dark d-flex align-items-center justify-content-center border border-light rounded-circle"
                      >
                        <i class="bi bi-clock-history"></i>
                      </span> */}
                      {/* <span
                        class="position-absolute top-0 st art-100 size-20px p-0 translate-middle bg-primary text-dark d-flex align-items-center justify-content-center border border-light rounded-circle"
                      >
                        <span>{getCollections?.length}</span><span class="visually-hidden">New alerts</span>
                      </span> */}
                    </div>
                    <div className="">Viewed</div>
                  </button>                  
                </li>
                {userType && userType !== "USER" ? (
                  <li className="nav-item px-1">
                    <a to="signin.html" className="nav-link px-0">
                      <span className="icons rounded border border-primary overflow-hidden size-40px">
                        <img
                          src={currentUser.image || 'assets/images/user.jpg'}
                          alt=""
                          className="object-fit-fill"
                        />
                      </span>
                      <span className="px-2 d-sm-inline-block d-none">
                        {currentUser.name || 'Guest'} { currentUser.email || currentUser.phone ? <br /> : null} {currentUser.phone || currentUser.email}
                      </span>
                    </a>
                  </li>
                ): null}
              </ul>
            </div>
            <div className={`${!sidebar ? "order-lg-2 order-1" : 'order-1'} col-auto`}>
              <nav
                className="py-lg-0"
                aria-label="Fifth navbar example"
              >
                <div className="container-fluid ps-xxl-3 px-0">
                  <Collapse isOpen={showSidebar || false} navbar>
                    <div className="menus-holder w-100 col-12">
                      <div className="row w-100 gx-0 ">
                        {!sidebar ? (
                          <div className="col-lg col-12">
                            <ul className="navbar-nav w-100">
                              <li className="nav-item dropdown">
                                <NavLink className="nav-link" to="/">
                                  Home
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink className="nav-link" to="/about">
                                  About Us
                                </NavLink>
                              </li>
                              <li class="nav-item dropdown d-none">
                                <UncontrolledDropdown nav inNavbar>
                                  <DropdownToggle nav caret>
                                    Tools
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <NavLink
                                      className="nav-link text-capitalize"
                                      to="tools/schema-generator"
                                    >
                                      Schema Generater
                                    </NavLink>
                                    <NavLink
                                      className="nav-link text-capitalize"
                                      to="tools/sitemate-generator"
                                    >
                                      Sitemap Generator
                                    </NavLink>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                {/* <NavLink
                              class="nav-link dropdown-toggle"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Tools
                            </NavLink>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <div class="card card-body">
                                <div class="row row-cols-lg-4 g-3 row-cols-1 px-md-4 py-3">
                                  <div class="col">
                                    <p class="text-danger mb-2">
                                      Boy’s Clothing
                                    </p>
                                    <ul class="list-unstyled ">
                                      <li>
                                        <a href="#">T-Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Trousers</a>
                                      </li>
                                      <li>
                                        <a href="#">Jackets</a>
                                      </li>
                                      <li>
                                        <a href="#">Sweatshirts</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col">
                                    <p class="text-danger mb-2">
                                      Boy’s Clothing
                                    </p>
                                    <ul class="list-unstyled ">
                                      <li>
                                        <a href="#">T-Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Trousers</a>
                                      </li>
                                      <li>
                                        <a href="#">Jackets</a>
                                      </li>
                                      <li>
                                        <a href="#">Sweatshirts</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col">
                                    <p class="text-danger mb-2">
                                      Boy’s Clothing
                                    </p>
                                    <ul class="list-unstyled ">
                                      <li>
                                        <a href="#">T-Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Trousers</a>
                                      </li>
                                      <li>
                                        <a href="#">Jackets</a>
                                      </li>
                                      <li>
                                        <a href="#">Sweatshirts</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col">
                                    <p class="text-danger mb-2">
                                      Boy’s Clothing
                                    </p>
                                    <ul class="list-unstyled ">
                                      <li>
                                        <a href="#">T-Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Trousers</a>
                                      </li>
                                      <li>
                                        <a href="#">Jackets</a>
                                      </li>
                                      <li>
                                        <a href="#">Sweatshirts</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col">
                                    <p class="text-danger mb-2">
                                      Boy’s Clothing
                                    </p>
                                    <ul class="list-unstyled ">
                                      <li>
                                        <a href="#">T-Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Trousers</a>
                                      </li>
                                      <li>
                                        <a href="#">Jackets</a>
                                      </li>
                                      <li>
                                        <a href="#">Sweatshirts</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col">
                                    <p class="text-danger mb-2">
                                      Boy’s Clothing
                                    </p>
                                    <ul class="list-unstyled ">
                                      <li>
                                        <a href="#">T-Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Trousers</a>
                                      </li>
                                      <li>
                                        <a href="#">Jackets</a>
                                      </li>
                                      <li>
                                        <a href="#">Sweatshirts</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col">
                                    <p class="text-danger mb-2">
                                      Boy’s Clothing
                                    </p>
                                    <ul class="list-unstyled ">
                                      <li>
                                        <a href="#">T-Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Trousers</a>
                                      </li>
                                      <li>
                                        <a href="#">Jackets</a>
                                      </li>
                                      <li>
                                        <a href="#">Sweatshirts</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col">
                                    <p class="text-danger mb-2">
                                      Boy’s Clothing
                                    </p>
                                    <ul class="list-unstyled ">
                                      <li>
                                        <a href="#">T-Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Shirts</a>
                                      </li>
                                      <li>
                                        <a href="#">Trousers</a>
                                      </li>
                                      <li>
                                        <a href="#">Jackets</a>
                                      </li>
                                      <li>
                                        <a href="#">Sweatshirts</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <Sidebar/>
                        )}
                      </div>
                    </div>
                    {/* Here we use navbar toggle to make navbar responsive,                  */}
                  </Collapse>
                  <NavbarToggler onClick={toggleNavbar}>
                    <span className />
                    <span className />
                    <span className />
                    <span className />
                    <span className />
                  </NavbarToggler>
                </div>
              </nav>
            </div>
          </div>
        </Navbar>
        <CartSidebar show={showCart} syncCollections={(e) => syncCollections()} handleClose={() => {
          setShowCart(!showCart); 
          dispatch(needToSaveProduct('')) 
        }}/>
        <RecentlyViewed show={showRecentlyViewed} syncCollections={(e) => syncCollections()} handleClose={() => {
          setShowRecentlyViewed(!showRecentlyViewed); 
          dispatch(needToSaveProduct('')) 
        }}/>
      </div>
    </>
  );
}

export default WebHeader;
