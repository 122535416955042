import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";

function WebFooter({ sidebar }) {
  // specified the navbar of the page
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []); 

  return (
    <footer className="p-md-5 bg-lighter-secondary">
      <div className="container">
        <div className="row gy-3">
          <div className="col-md-5 col-12">
            <div className="card-title"><a href="#"><Logo/></a></div>
            <div>A leading Tools list and directory, helping AI enthusiasts and professionals make the best out of AI products and services, all on one place.</div>
          </div>
          <div className="col-md-7 col-12">
            <div className="row gy-3">
              <div className="col-md-3 col-12">
                <div className="fw-bold">Pages</div>
                <div className="row g-2">
                  <div className="col-12"><Link to="/" className="card-title">Home</Link></div>
                  <div className="col-12"><Link to="/about" className="card-title">About Us</Link></div>
                  <div className="col-12"><Link to="/contact" className="card-title">Contact Us</Link></div>
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="fw-bold">Resources</div>
                <div className="row g-2">
                  <div className="col-12"><Link to="/all-categories" className="card-title">All categories</Link></div>
                  <div className="col-12"><Link to="/search" className="card-title">All Tools</Link></div>
                  <div className="col-12">{/* <Link to="/blogs" className="card-title">Blog</Link> */}</div>
                </div>
              </div>
              {/* <div className="col-md-3 col-12">
                <div>Resources</div>
                <div className="card-title">Blog</div>
                <div className="card-title">All categories</div>
                <div className="card-title">AI usecases</div>
              </div>
              <div className="col-md-3 col-12">
                <div>Resources</div>
                <div className="card-title">Blog</div>
                <div className="card-title">All categories</div>
                <div className="card-title">AI usecases</div>
              </div> */}  
            </div>
          </div>
        </div>
        <hr/>
          <div className="col-12 text-center">© Copyright {window.location.origin}. All Rights Reserved <Link to="/privacy-policy" className="mx-3 card-title">Privacy policy</Link> 
          {/* <span className="mx-3 card-title">Service terms</span> */}
          </div>
      </div>
    </footer>
  );
}

export default WebFooter;
