// import thunk from "redux-thunk";
// import reducers from "./reducers";
// import { applyMiddleware, compose } from "redux";
// import { legacy_createStore } from "redux";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = legacy_createStore(
//   reducers,
//   composeEnhancers(applyMiddleware(thunk)),
// //   {},
// //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// // const store = createStore(reducers, applyMiddleware(thunk));

// export default store;


// store.js

import { configureStore } from '@reduxjs/toolkit';
import { api } from './api/endpoints';
import authReducer from './slices/authSlice';
import productReducer from './slices/productSlice';
import notificationReducer from './notificationSlice';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    productSlice: productReducer,
    notifications: notificationReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export default store;


