// productSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: [], // Array to store products
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      const product = action.payload;
      const existingProduct = state.products.find(p => p._id === product._id);

      if (!existingProduct) {
        state.products.push(product);
      }
    },
  },
});

export const { addProduct } = productSlice.actions;

export default productSlice.reducer;
