import React, { useEffect, useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { getLocalStorage, setLocalStorage } from "../helpers/Utils";
import { themeUserCollectionKey} from "../config/config";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../redux/notificationSlice";

const CartSidebar = ({show, syncCollections, handleClose}) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [collectionName, setCollectionName] = useState([])
  const [collectionMatched, setCollectionMatched] = useState([])
  // const [getUser, setGetUser] = useLocalStorage(themeUserStorageKey, getLocalStorage(themeUserStorageKey) || [])
  const [collections, setCollections] = useState(getLocalStorage(themeUserCollectionKey) || [])
  const getCollectionsWithoutFilter = Array.isArray(collections) ? collections?.filter((d) => d) : []
  const getCollectionsMatched = [
    ...getCollectionsWithoutFilter.filter(d => collectionMatched.some(c => c.title === d.title))?.map(c => ({...c, matched : true})),
    ...getCollectionsWithoutFilter.filter(d => !(collectionMatched.some(c => c.title === d.title)))
  ]
  const getCollections = [
    ...getCollectionsMatched.filter(d => collectionName.some(c => c.title === d.title))?.map(c => ({...c, searched : true})),
    ...getCollectionsMatched.filter(d => !(collectionName.some(c => c.title === d.title)))
  ]
  const getNeedToSaveProduct = useSelector((state) => state.auth?.needToSaveProduct);
  console.log("🚀 ~ CartSidebar ~ getCollections:", getCollectionsMatched, getCollections, getCollectionsWithoutFilter, collectionName, collectionMatched, getNeedToSaveProduct, collections, collectionName)

  // const { data: getCollectionsByProductData, ...getCollectionByProduct } = useFetchCollectionListQuery();
  
  // const [createCollection, { isLoading: createCollectionLoading }] = useCreateCollectionMutation();
 
  const handleSubmitCollection = async (collectionTitle, _id, description) => {
    debugger;
    let getUpdatedCollections = getCollectionsWithoutFilter
    let getCollection = getUpdatedCollections?.find(d => d.title === collectionTitle)
    let getProduct = getUpdatedCollections?.find(d => d.title === collectionTitle)?.product || []
    const toggleProduct = getProduct.some(d => d._id === getNeedToSaveProduct._id) ? getProduct.filter(d => d._id !== getNeedToSaveProduct._id) : getProduct.concat(getNeedToSaveProduct)
    const message = getProduct.some(d => d._id === getNeedToSaveProduct._id) ? "Removed from" : "Added to"
    try { 
      const reqObj = {
        description: description || "", 
        _id: _id || collectionTitle ? collectionTitle?.toString()?.replaceAll(" ", '-')?.toLowerCase() : "",
        title: collectionTitle,
        product: toggleProduct?.filter(d => d),
        slug_url: collectionTitle ? collectionTitle?.toString()?.replaceAll(" ", '-')?.toLowerCase() : "",
        syncPending: true,
        // published_on: "",
        // is_published: false,
        // is_deleted: false,
        // added_by: "",
        // deleted_at: "",
        // added_at: "",
        // updated_at: "",
      };
      // if (response?.success) {
        dispatch(
          addNotification(
            message === 'Added to' ? "success" : "warning",
            "",
            `Your Tool ${message} ${reqObj?.title} Successfully`,
          )
        );
        // setCollectionName([])
        // if(token){
        //   getCollectionByProduct.refetch();
        // }
        const getCollectionsUpdated = getUpdatedCollections.some(d => d.title === reqObj.title) ? getUpdatedCollections?.map(d => d.title === reqObj.title ? ({...d, ...reqObj}) : d) : getUpdatedCollections.concat(reqObj)
        setLocalStorage(themeUserCollectionKey, getCollectionsUpdated?.filter(d => d))
        // window.dispatchEvent(new StorageEvent('storage', { key: themeUserCollectionKey }))
        handleClose()
      // }
    } catch (error) {}
  };

  // const toggleCollection = (collection) => {
  //   const findCollection = getCollections.filter(d => d._id === collection._id)
  //   if(findCollection.some(d => d.slug_url === params?.product)){
  //     setCollectionName(collection)
  //   }else{
  //     setCollectionName(collection)
  //   }
  // }

  useEffect(() => {
    if(getCollections && Array.isArray(getCollections)){
      setCollectionMatched(getCollections?.filter(d => d.product?.some(c => c?._id?.toLowerCase() === getNeedToSaveProduct?._id?.toLowerCase())))
    }
  }, [getNeedToSaveProduct?._id])

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === themeUserCollectionKey) {
        setCollections(getLocalStorage(themeUserCollectionKey) || [])
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Offcanvas
      className="bg-light text-dark"
      isOpen={show}  // Use isOpen prop to control visibility
      direction="end"
      scrollable
      toggle={handleClose}
    >
      <OffcanvasHeader className="bg-primary" toggle={handleClose}>
        <div className="row">
          <div className="col">{getCollections?.length} Saved Tools
            <button className="btn p-0 ms-2" title="Sync Collections" onClick={() => syncCollections()}><i class="bi bi-arrow-repeat"></i></button>
          </div>
          <div className="col-auto">
            <Link className="btn p-0" title="View All Saved tools" to="/saved">View All</Link>
          </div>
        </div>
      </OffcanvasHeader>  
      {getCollections?.length > 0 ? <OffcanvasBody>
        <div class="list-group">
          {getCollections?.map(d => (
            <a
            href="#"
            onClick={() => {
              handleSubmitCollection(d.title, d._id);
            }}
            class={`list-group-item rounded border my-1 list-group-item-action flex-column align-items-start ${d.searched ? 'bg-secondary' : ""} ${d.matched ? 'bg-primary' : ""}`}
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{d.title}</h5>
              <button className={`btn p-0`}><i className={`bi ${d.matched ? "bi-dash-lg" : "bi-plus"}`}></i></button>
            </div>
            {/* <p class="mb-1">Paragraph</p> */}
            <small class="text-muted">{d.description}</small>
          </a>
        ))}
        </div>
      </OffcanvasBody>  : 
      
      <div className="p-3 text-center">
        <p className="display-3 text-danger"><i className="bi bi-heartbreak"></i></p>
        <p>Oops, Looks like You did Not Saved Any Tool</p>
        <p>Click on Heart in Any of Tool And Create New Collection to save Tool</p>
      </div> }
      {getNeedToSaveProduct?._id ? <div class={`input-group p-2 ${getCollections?.length > 0 ? "bg-light-primary shadow": ""}`}>
        <input
          type="text"
          class="form-control"
          placeholder="New Collection Name"
          aria-label="Button"
          aria-describedby=""
          onChange={(e) => setCollectionName(e.target.value.split(",").map(d => ({title: d})))}
          value={collectionName?.map(d => d.title).join()}
        />
        <button class="btn btn-primary" type="button" 
        // onClick={(e) => {if(getCollections.some(d => collectionName?.title?.toUpperCase() !== d.title?.toUpperCase())){handleSubmitCollection(collectionName)}}} 
        // disabled={getCollections.some(d => collectionName?.some(c => c.title?.toUpperCase() === d.title?.toUpperCase()))}
        onClick={(e) => handleSubmitCollection(collectionName?.[0]?.title)} 
        >
         {getCollections.some(d => collectionName?.some(c => c.title?.toUpperCase() === d.title?.toUpperCase())) ? "Save" : "New Collection"}
        </button>
      </div> : null}
    </Offcanvas>
  );
};

export default CartSidebar;
