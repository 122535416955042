// slices/authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { setLocalStorage } from '../../helpers/Utils';
import { themeUserStorageKey } from '../../config/config';

const initialState = {
  token: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      debugger
      const { data } = action.payload;
      if(data.user){
        setLocalStorage(themeUserStorageKey, data.user)
        state.token = data.user.token;
        state.user = data.user;
        // setUserRoutes(data.routes)
      }
    },
    logout: (state) => {
      debugger
      setLocalStorage(themeUserStorageKey, null)
      state.token = null;
      state.user = null;
    },
    needToSaveProduct: (state, action) => {
      const needToSaveProduct = action.payload;
      state.needToSaveProduct = needToSaveProduct;
    },
    // needToSaveProduct: (state, action) => {
    //   const product = action.payload;
    //   const existingProduct = state.products.find(p => p._id === product._id);
    //   if (!existingProduct) {
    //     state.products.push(product);
    //   }
    // },
  },
});

export const { setCredentials, logout, needToSaveProduct } = authSlice.actions;

export default authSlice.reducer;
