import React, { useState, lazy, Suspense } from 'react';
import { Label } from 'reactstrap';
import { Field, useField, useFormikContext } from 'formik';
import { getUrlExcludingHost, openNewPage } from '../helpers/Utils';
import Loader from '../layout/Loader';
// Lazy load components to improve performance
const FormikReactSelect = lazy(() => import('./FormikFields').then(module => ({ default: module.FormikReactSelect })));
// const FormikDatePicker = lazy(() => import('./FormikFields').then(module => ({ default: module.FormikDatePicker })));
const FormikRadioButtonGroup = lazy(() => import('./FormikFields').then(module => ({ default: module.FormikRadioButtonGroup })));
const FormikCustomRadioGroup = lazy(() => import('./FormikFields').then(module => ({ default: module.FormikCustomRadioGroup })));
// const FormikTagsInput = lazy(() => import('./FormikFields').then(module => ({ default: module.FormikTagsInput })));
const CreatableSelect = lazy(() => import('react-select/creatable').then(module => ({ default: module.CreatableSelect })));
const FileUploader = lazy(() => import('./FileUploader'));

const CustomFields = ({ type, label, name, addButton, componentName, ...rest }) => {
  const { submitCount, setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta, helpers] = useField({ ...rest, name });
  const [selectResponseddata, setSelectResponseddata] = useState([])

  // Function to set field value by name
  const setValue = (name, value) => {
    setFieldValue(name, value);
    setFieldTouched(name, true);
  };

  const getInputComponent = () => {

    const setMatChedKeys = (e) => {
      let responseArray = []
      if(e &&  typeof e === 'string'){
        responseArray = [e];
      }
      if(e && typeof e === 'object'){
        responseArray = [e?.[addButton?.responseMatchedKey || '_id']]
      }
      if(Array.isArray(e)){
        responseArray = e?.map(d => d[addButton?.responseMatchedKey || '_id'])
      }
      if(responseArray?.filter(d => d)?.length > 0){
        setSelectResponseddata(responseArray)
      }
    }

    const setSelectedData = () => {
      if(!rest.loading && rest?.options?.length > 0 && selectResponseddata?.length){
        const matchedData = rest.options?.filter(d => selectResponseddata.includes(d.value))
        setValue(name, rest?.isMulti ? matchedData : matchedData?.[0])
        if(matchedData?.length > 0){
          setSelectResponseddata([])
        }
      }
    }
    if(!rest.loading && rest?.options?.length > 0 && selectResponseddata?.length){
      setSelectedData()
    }

    const openPage = (e) => {
      openNewPage({
        pageLinkFrom: getUrlExcludingHost(window.location.href),
        pageLinkTo: addButton?.pageLink,
        refetch: addButton.refetch,
        setAddedResponse: (e) => {
          if(typeof addButton?.setAddedResponse === 'function'){
            addButton.setAddedResponse(e.data)
          }else{
            setMatChedKeys(e?.data)
          }
        },
      });
    };

    if (type === 'select') {
      return <>
      <div className="row">
        <div className="col">
          <FormikReactSelect 
          id={name} 
          name={name} 
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          value={field.value}
          // onChange={(fieldName, fieldValue) => {setMatChedKeys(fieldValue);
          //   console.log("setMatChedKeys", fieldName, fieldValue)
          // }} 
          {...rest}/>
        </div>
        {addButton ? <div className="col-auto ps-0">
            <button
              type="button"
              name=""
              id=""
              class="btn btn-primary"
              {...addButton}
              onClick={() => {
                openPage()
              }}
            >
              {addButton?.label || 'Add New'}
            </button>
        </div> : null}
      </div>
      </>;
    }
    
    if (type === 'text' && rest.options?.length > 0) {

      
      const handleChange = (newValue) => {
        setFieldValue(name, newValue);
      };

      const handleBlur = () => {
        setFieldTouched(name, true);
      };
      

      const getValue = () => {
        return rest.options.filter((option) => field?.value?.value === option.value);
      };

      const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
      });

      const handleCreate = (inputValue) => {
        const newOption = createOption({inputValue});
        helpers.setValue([...field?.value, newOption]);
      };

      // Ensure options are correctly formatted for CreatableSelect
      // const formattedOptions = rest.options.map((option) => ({
      //   label: option,
      //   value: option,
      // }));
      const formattedOptions = rest.options || []

      // https://www.dreammachineai.co/
      return <>
      <div className="row">
        <div className="col">
          <CreatableSelect
            // className='form-control p-0'
            id={name}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            onCreateOption={handleCreate}
            value={getValue()}
            isClearable
            {...rest}
            // className='bg-light'
            options={formattedOptions}
            classNamePrefix="react-select"
            className={`react-select ${rest.className}`}
          /> 
        </div> 
      </div>
      </>;
    } 

    // if(type === 'text' && rest.options?.length > 0){
      
    //     const handleChange = (val) => {
    //       setFieldValue(name, val);
    //     };
      
    //     const handleBlur = () => {
    //       setFieldTouched(name, true);
    //     };
      
    //     return (
    //       <CreatableSelect
    //       options={rest.options}
    //       isMulti={rest.isMulti}
    //         onChange={handleChange}
    //         onBlur={handleBlur}
    //         value={field.value}
    //         defaultValue={field.value}
    //         {...rest}
    //         classNamePrefix="react-select"
    //         className={`react-select ${rest.className}`}
    //       />
    //     );
    // }

    if (type === 'radio') {
      return <>
      <div className="d-flex">
          {componentName === 'custom' ? (
            <FormikCustomRadioGroup 
            id={name} 
            name={name} 
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            value={field.value}
            // onChange={(fieldName, fieldValue) => {setMatChedKeys(fieldValue);
            //   console.log("setMatChedKeys", fieldName, fieldValue)
            // }} 
            {...rest}/>
          ) : (
            <FormikRadioButtonGroup 
            id={name} 
            name={name} 
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            value={field.value}
            // onChange={(fieldName, fieldValue) => {setMatChedKeys(fieldValue);
            //   console.log("setMatChedKeys", fieldName, fieldValue)
            // }} 
            {...rest}/>
          )}
      </div>
      </>;
    }

    // if (type === 'date') {
    //   return <FormikDatePicker id={name} name={name} {...rest} {...field} />;
    // }

    if (type === 'file') {
      return <FileUploader id={name} name={name} {...rest} />;
    }

    return <Field type={type} as={['textarea'].includes(type) ? type: ''} id={name} name={name} {...rest} invalid={meta.error} />;
  };

  return (
    <div className="position-relative custom-field">
      {label ? (
        <Label htmlFor={name} className={type === 'checkbox' || type === 'radio' ? 'd-block' : ''}>
          {label} {rest.required ? <span className="text-danger">*</span> : null}
        </Label>
      ) : null}
        <Suspense fallback={<Loader />}>
          {getInputComponent()}
        </Suspense>
      {(meta.touched && meta.error && meta.value?.length > 0) || submitCount > 0 ? (
        <div className="invalid-feedback d-block">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CustomFields;
