export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

export const prifix = "glr";
export const customerRoot = "/customer";
export const businessRoot = "/business";
export const adminRoot = "/admin";
export const baseUrl = window.location.href.includes('localhost') ? "http://localhost:8000" : "https://backend-dwkk.onrender.com";
// export const baseUrl = "https://backend-dwkk.onrender.com";
export const searchPath = `${customerRoot}/#`;
export const servicePath = "https://api.coloredstrategies.com";

export const themeSettingStorageKey = `${prifix}_theme_setting`;
export const themeUserStorageKey = `${prifix}_user`; 
export const themeUserCollectionKey = `${prifix}_collection`; 
export const themeUserRecentlyViewedKey = `${prifix}_recently_viewed`; 
export const themeSidebarStorageKey = `${prifix}_show_sidebar`; 

export const localUser = {
  [themeSettingStorageKey]: {
    isDarkSwitchActive: true,
    defaultColor: "golden",
    defaultSize: "16",
  },
  [themeUserStorageKey]: {
    name: "prawesh kumar",
    userName: "prawesh@kumar",
    Phone: "9625906313",
    phoneVerified: false,
    email: "praweshwebs@gmailcom",
    emailVerified: true,
    userType : 'USER',
    token : ""
  },
};

export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
];

// HEADER CONFIGRATION
export  const sidebarWidth = '300px'

export const getRollandPermission = {
  USER: {
    login: { 
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    pageVisit : {
      '/login' : true
    }
  },
  CUSTOMER: {
    login: {
      visit: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    pageVisit : {
      '/login' : false
    }
  },
}; 

export const sidebarRoutes = [
  {
    url: "/dashboard",
    view: true
  },
  {
    url: "/users/user-master",
    view: true
  }
]

// ActionBtns

export const ADD = 'add'
export const EDIT = 'edit'
export const VIEW = 'view'
export const DELETE = 'delete'