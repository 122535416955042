import React from "react";
import { Link, Outlet } from "react-router-dom";
import ShareButtons from "../layout/ShareButtons";

export function convertJsonToHtml(jsonData) {
  let html = "<div>";

  function jsonToHtml(key, value) {
    if (Array.isArray(value)) {
      html += `<div><strong>${key}</strong>: [<ul>`;
      value.forEach((item, index) => {
        html += "<li>";
        if (typeof item === "object" && item !== null) {
          jsonToHtml(`${key}[${index}]`, item);
        } else {
          html += `${item}`;
        }
        html += "</li>";
      });
      html += "</ul>]</div>";
    } else if (typeof value === "object" && value !== null) {
      html += `<div><strong>${key}</strong>: {<ul>`;
      Object.entries(value).forEach(([nestedKey, nestedValue]) => {
        html += "<li>";
        jsonToHtml(nestedKey, nestedValue);
        html += "</li>";
      });
      html += "</ul>}</div>";
    } else {
      html += `<div><strong>${key}</strong>: ${value}</div>`;
    }
  }

  if (typeof jsonData === "object" && jsonData !== null) {
    Object.entries(jsonData).forEach(([key, value]) => {
      jsonToHtml(key, value);
    });
  } else {
    html += `<div>${jsonData}</div>`;
  }

  html += `</div>`;
  return html;
}

const content = {
  privacyPolicy: {
    effectiveDate: "[Insert Date]",
    introduction: {
      description:
        "At [Your Website Name] ('we', 'us', or 'our'), we are committed to protecting your privacy and ensuring the safety of your personal information. This Privacy Policy explains how we collect, use, and disclose your data when you use our website and its services, including the tools we provide for developers, such as JSON processing, merging, and obfuscating utilities. By using our services, you agree to the collection and use of your data in accordance with this policy.",
    },
    informationWeCollect: {
      personalInformation: {
        description:
          "We do not require you to submit any personal information to use our tools. However, if you choose to contact us through forms or email, we may collect your name, email address, or any other details you provide.",
      },
      usageData: {
        description:
          "When you use our website, we may collect data about your interactions with our tools and services. This may include your IP address, browser type, browser version, the pages you visit, the time and date of your visit, and other diagnostic data.",
      },
      toolData: {
        description:
          "Any JSON data or other inputs you provide to use our tools (e.g., JSON formatting, merging, or obfuscation) will be processed only temporarily during the session. We do not store this data once the session is complete.",
      },
    },
    howWeUseYourData: {
      description: "We use your data for the following purposes:",
      purposes: [
        "To provide and maintain our services.",
        "To monitor and analyze the usage of our website and tools to improve functionality.",
        "To communicate with you if you've contacted us with a query or support request.",
      ],
    },
    dataSecurity: {
      description:
        "We prioritize the security of your data. We implement standard security measures to prevent unauthorized access or disclosure. However, no method of transmission over the internet is 100% secure, and we cannot guarantee its absolute security.",
    },
    cookiesAndTrackingTechnologies: {
      description:
        "Our website may use cookies and similar tracking technologies to enhance user experience. These cookies are not linked to any personally identifiable information.",
    },
    thirdPartyLinks: {
      description:
        "Our website may contain links to third-party sites. We are not responsible for the privacy practices or the content of such external websites. We recommend reviewing the privacy policies of any third-party sites you visit.",
    },
    changesToPrivacyPolicy: {
      description:
        "We may update this Privacy Policy from time to time to reflect changes in our practices. Any updates will be posted on this page with an updated effective date. We encourage you to review this policy periodically for any changes.",
    },
    contactUs: {
      description:
        "If you have any questions about this Privacy Policy, please contact us at [Your Email Address].",
    },
  },
  functionExplanation: {
    description: "Function to Create HTML from JSON",
    functionCode:
      "function createHtmlFromJson(jsonData) {\n    let html = '<div>';\n\n    function jsonToHtml(key, value) {\n        if (typeof value === 'object' && value !== null) {\n            html += `<div><strong>${key}</strong>: {</div>`;\n            Object.entries(value).forEach(([nestedKey, nestedValue]) => {\n                jsonToHtml(nestedKey, nestedValue);\n            });\n            html += `<div>}</div>`;\n        } else {\n            html += `<div><strong>${key}</strong>: ${value}</div>`;\n        }\n    }\n\n    Object.entries(jsonData).forEach(([key, value]) => {\n        jsonToHtml(key, value);\n    });\n\n    html += '</div>';\n    return html;\n}\n\n// Example usage:\nconst sampleJson = {\n    name: \"John Doe\",\n    age: 30,\n    skills: {\n        programming: \"JavaScript\",\n        design: \"Photoshop\"\n    }\n};\n\ndocument.body.innerHTML = createHtmlFromJson(sampleJson);",
    descriptionDetails: {
      summary:
        "This function iterates through a JSON object and converts it to a structured HTML format, which can be displayed in a browser.",
      steps: [
        "Create an empty HTML structure.",
        "Use recursion to handle nested objects in JSON.",
        "Each key-value pair is converted into an HTML tag with appropriate styling.",
        "The result is appended into the HTML string, which can then be injected into the DOM.",
      ],
    },
  },
};

const ContactUs = () => {
  return (
    <>
      {/* <Helmet>
        <meta name="description" content="Get in touch with us for inquiries, collaborations, or support. Whether you need help with our developer tools, marketing solutions, or testing utilities, we are here to assist you."/>
        <meta name="keywords" content="contact us, developer tools support, marketing tools inquiries, testing tools collaboration, JSON tools, marketing automation, testing automation, customer support"/>
        <meta name="robots" content="index, follow"/>
        <meta name="author" content="Your Agency Name"/>
        <title>Contact Us | Your Agency Name - Reach Out for Inquiries & Support</title>
      </Helmet> */}

      <main className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="banner bg-lighter-primary text-center py-5">
              <h2 className="font-special mb-0 py-5 h1">PRIVACY POLICY</h2>
            </div>
          </div>
        </div>

        <section className="contact">
          <div className="container">
            <div class="markdown prose w-full break-words dark:prose-invert light">
              <p>
                we are committed
                to protecting your privacy and ensuring the safety of your
                personal information. This Privacy Policy explains how we
                collect, use, and disclose your data when you use our website
                and its services, including the tools we provide for developers,
                such as JSON processing, merging, and obfuscating utilities. By
                using our services, you agree to the collection and use of your
                data in accordance with this policy.
              </p>
              <h3>1. Information We Collect</h3>
              <p>We may collect the following types of information:</p>
              <ul>
                <li>
                  <p>
                    <strong>Personal Information</strong>: We do not require you
                    to submit any personal information to use our tools.
                    However, if you choose to contact us through forms or email,
                    we may collect your name, email address, or any other
                    details you provide.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Usage Data</strong>: When you use our website, we
                    may collect data about your interactions with our tools and
                    services. This may include your IP address, browser type,
                    browser version, the pages you visit, the time and date of
                    your visit, and other diagnostic data.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Tool Data</strong>: Any JSON data or other inputs
                    you provide to use our tools (e.g., JSON formatting,
                    merging, or obfuscation) will be processed only temporarily
                    during the session. We do not store this data once the
                    session is complete.
                  </p>
                </li>
              </ul>
              <h3>2. How We Use Your Data</h3>
              <p>We use your data for the following purposes:</p>
              <ul>
                <li>To provide and maintain our services.</li>
                <li>
                  To monitor and analyze the usage of our website and tools to
                  improve functionality.
                </li>
                <li>
                  To communicate with you if you've contacted us with a query or
                  support request.
                </li>
              </ul>
              <h3>3. Data Security</h3>
              <p>
                We prioritize the security of your data. We implement standard
                security measures to prevent unauthorized access or disclosure.
                However, no method of transmission over the internet is 100%
                secure, and we cannot guarantee its absolute security.
              </p>
              <h3>4. Cookies and Tracking Technologies</h3>
              <p>
                Our website may use cookies and similar tracking technologies to
                enhance user experience. These cookies are not linked to any
                personally identifiable information.
              </p>
              <h3>5. Third-Party Links</h3>
              <p>
                Our website may contain links to third-party sites. We are not
                responsible for the privacy practices or the content of such
                external websites. We recommend reviewing the privacy policies
                of any third-party sites you visit.
              </p>
              <h3>6. Changes to This Privacy Policy</h3>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices. Any updates will be posted on this
                page. We encourage you to review
                this policy periodically for any changes.
              </p>
              <h3>7. Contact Us</h3>
              <p>
                If you have any questions about this Privacy Policy, please
                <Link to="/contact" className="text-primary fw-bold"> contact us</Link>.
              </p>
            </div>
          </div>
        </section>
      </main>

      <Outlet />
    </>
  );
};

export default ContactUs;
