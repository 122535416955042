import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { breadCrumbSchemaData } from "../view/seo/Schemas";

const BreadCrumb = ({name}) => {
  const [pageName, setPageName] = useState('')
  const location = useLocation();
  const segments = location.pathname.split("/").filter((segment) => segment !== "");
  const breadcrumbs = [{ label: "Home", path: "/" }];

  const formatPathName = () => {
    const pathname = location.pathname;
    const lastSegment = pathname.substring(pathname.lastIndexOf('/') + 1);
    const formattedSegment = lastSegment
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    setPageName(formattedSegment)
  };

  useEffect(() => {
    formatPathName(location)
  }, [])

  segments.forEach((segment, index) => {
    const path = `/${segments.slice(0, index + 1).join("/")}`;
    const label = segment.replaceAll("-", " ");
    breadcrumbs.push({ label, path });
  });
  
  const generateJSONLD = (data) => {
    return breadCrumbSchemaData(data);
  }
  
  useEffect(() => {
    const jsonLdData = generateJSONLD(breadcrumbs);
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(jsonLdData);
    document.head.appendChild(script);
  }, [name]);

  return (
    <>
    <h5>{name || pageName}</h5>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbs.map(({ label, path }) => (
          <li className="breadcrumb-item" key={path}>
            <a href={path}>{label}</a>
          </li>
        ))}
      </ol>
    </nav>
    </>
  );
};

export default BreadCrumb;
