export const categoryList = [
    {
        _id: "dataVisualization",
        title: "Data Visualization",
        description: "Clear the values of json",
        slug_url: "data-visualization", 
    }, 
    {
        _id: "jsontools",
        title: "JSON Tools",
        description: "Clear the values of json",
        slug_url: "json-tools", 
    }, 
    {
        _id: "downloadAndPrint",
        title: "Download And Print",
        description: "Clear the values of json",
        slug_url: "download-and-print-tools", 
    }, 
    {
        _id: "cssTools",
        title: "CSS Tools",
        description: "CSS Tools",
        slug_url: "css-tools", 
    }, 
    {
        _id: "seoTools",
        title: "SEO Tools",
        description: "SEO Tools",
        slug_url: "seo-tools",
    }, 
    {
        _id: "textTools",
        title: "Text Tools",
        description: "Text Tools",
        slug_url: "text-tools",
    }, 
]