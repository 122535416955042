
// ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import authSlice from '../redux/slices/authSlice';
import { useSelector } from 'react-redux'; 
import { sidebarRoutes, themeUserStorageKey } from '../config/config';
import { getLocalStorage } from '../helpers/Utils';

const isAuthenticated = () => {
  const {token} = getLocalStorage(themeUserStorageKey, null)
  // Add logic to verify token if necessary
  return !!token;
};


export const getUserPermissions = async () => {
  const {token} = getLocalStorage(themeUserStorageKey, null)
  if (!token) return [];

  // const response = await fetch('/api/user/permissions', {
  //   headers: {
  //     Authorization: `Bearer ${user.token}`
  //   }
  // });

  // if (response.ok) {
  //   const data = await response.json();
  //   return data.permissions;
  // } else {
  //   return [];
  // }
};

const ProtectedRoute = ({ children, requiredPermission }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isPermission, setIsPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const checkPermissions = async () => {
      debugger
      if (isAuthenticated()) {
        setIsLoading(false);
        setIsAuthorized(true);
      }

      // const permissions = await getUserPermissions();
      // if (requiredPermission) {
      //   setIsAuthorized(permissions.includes(requiredPermission));
      // } else
      const hasCurrentUrlPermission = sidebarRoutes.some(d => d.url === window.location.pathname && d.view);
      const notHaveCurrentUrlPermission = sidebarRoutes.some(d => d.url === window.location.pathname && !d.view);
      if(hasCurrentUrlPermission){
        setIsPermission(hasCurrentUrlPermission); 
      }else{
        setIsPermission(notHaveCurrentUrlPermission)
      }
      setIsLoading(false);
    };

    checkPermissions();
  }, [requiredPermission]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a spinner
  }

  if (!isAuthorized && window.location.pathname !== '/login') {
    return <Navigate to="/login" />;
  }else if(isPermission){
    <Navigate to="/error" isPermission={isPermission} />;
  }

  return children;
};
export default ProtectedRoute
