import React from "react";
import { NavLink } from "react-router-dom";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const Sidebar = () => {
  return (
    <div className="col-lg-auto col-12">
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink className="nav-link" to="/">
            Dashboard
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="menu">
            MENU
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="roles">
            ROLE
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="users">
            USERS
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="media">
            MEDIA
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="category">
            CATEGORY
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="listing">
            LISTING
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="blogs">
            BLOGS
          </NavLink>
        </li>
        <li class="nav-item dropdown">
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Tools
            </DropdownToggle>
            <DropdownMenu right>
              <NavLink
                className="nav-link text-capitalize"
                to="tools/schema-generator"
              >
                Schema Generater
              </NavLink>
              <NavLink
                className="nav-link text-capitalize"
                to="tools/sitemate-generator"
              >
                Sitemap Generator
              </NavLink>
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* <NavLink
                                class="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Tools
                              </NavLink>
                              <div
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <div class="card card-body">
                                  <div class="row row-cols-lg-4 g-3 row-cols-1 px-md-4 py-3">
                                    <div class="col">
                                      <p class="text-danger mb-2">
                                        Boy’s Clothing
                                      </p>
                                      <ul class="list-unstyled ">
                                        <li>
                                          <a href="#">T-Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Trousers</a>
                                        </li>
                                        <li>
                                          <a href="#">Jackets</a>
                                        </li>
                                        <li>
                                          <a href="#">Sweatshirts</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col">
                                      <p class="text-danger mb-2">
                                        Boy’s Clothing
                                      </p>
                                      <ul class="list-unstyled ">
                                        <li>
                                          <a href="#">T-Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Trousers</a>
                                        </li>
                                        <li>
                                          <a href="#">Jackets</a>
                                        </li>
                                        <li>
                                          <a href="#">Sweatshirts</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col">
                                      <p class="text-danger mb-2">
                                        Boy’s Clothing
                                      </p>
                                      <ul class="list-unstyled ">
                                        <li>
                                          <a href="#">T-Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Trousers</a>
                                        </li>
                                        <li>
                                          <a href="#">Jackets</a>
                                        </li>
                                        <li>
                                          <a href="#">Sweatshirts</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col">
                                      <p class="text-danger mb-2">
                                        Boy’s Clothing
                                      </p>
                                      <ul class="list-unstyled ">
                                        <li>
                                          <a href="#">T-Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Trousers</a>
                                        </li>
                                        <li>
                                          <a href="#">Jackets</a>
                                        </li>
                                        <li>
                                          <a href="#">Sweatshirts</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col">
                                      <p class="text-danger mb-2">
                                        Boy’s Clothing
                                      </p>
                                      <ul class="list-unstyled ">
                                        <li>
                                          <a href="#">T-Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Trousers</a>
                                        </li>
                                        <li>
                                          <a href="#">Jackets</a>
                                        </li>
                                        <li>
                                          <a href="#">Sweatshirts</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col">
                                      <p class="text-danger mb-2">
                                        Boy’s Clothing
                                      </p>
                                      <ul class="list-unstyled ">
                                        <li>
                                          <a href="#">T-Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Trousers</a>
                                        </li>
                                        <li>
                                          <a href="#">Jackets</a>
                                        </li>
                                        <li>
                                          <a href="#">Sweatshirts</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col">
                                      <p class="text-danger mb-2">
                                        Boy’s Clothing
                                      </p>
                                      <ul class="list-unstyled ">
                                        <li>
                                          <a href="#">T-Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Trousers</a>
                                        </li>
                                        <li>
                                          <a href="#">Jackets</a>
                                        </li>
                                        <li>
                                          <a href="#">Sweatshirts</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col">
                                      <p class="text-danger mb-2">
                                        Boy’s Clothing
                                      </p>
                                      <ul class="list-unstyled ">
                                        <li>
                                          <a href="#">T-Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Shirts</a>
                                        </li>
                                        <li>
                                          <a href="#">Trousers</a>
                                        </li>
                                        <li>
                                          <a href="#">Jackets</a>
                                        </li>
                                        <li>
                                          <a href="#">Sweatshirts</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
