import React, { useEffect, useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { getLocalStorage, removeDuplicatesFromArrayByKey, setLocalStorage } from "../helpers/Utils";
import { themeUserRecentlyViewedKey, themeUserStorageKey } from "../config/config";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../redux/notificationSlice";
import { useCreateCollectionMutation, useFetchCollectionListQuery } from "../redux/api/endpoints";
import useLocalStorage from "../hooks/UseLocalStorage";
import { needToSaveProduct } from "../redux/slices/authSlice";
import { toolsList } from "../data/tools";

const RecentlyViewed = ({show, syncCollections, handleClose}) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [collectionName, setCollectionName] = useState("")
  const [collectionMatched, setCollectionMatched] = useState([])
  // const [getUser, setGetUser] = useLocalStorage(themeUserStorageKey, getLocalStorage(themeUserStorageKey) || [])
  // const [collections, setCollections] = useState(getLocalStorage(themeUserRecentlyViewedKey) || [])
  const collections = getLocalStorage(themeUserRecentlyViewedKey)
  const getCollectionsWithoutFilter = Array.isArray(collections) ? collections?.filter((d) => d) : []
  // const getCollectionsMatched = [
  //   ...getCollectionsWithoutFilter.filter(d => collectionMatched.some(c => c.title === d.title))?.map(c => ({...c, matched : true})),
  //   ...getCollectionsWithoutFilter.filter(d => !(collectionMatched.some(c => c.title === d.title)))
  // ]
  const getCollections = [
    ...getCollectionsWithoutFilter.filter(d => collectionName?.split(",")?.filter(s => s).some(c => [d.title, d.short_description].some(k => k.toUpperCase().includes(c.toUpperCase()))))?.map(c => ({...c, searched : true})),
    ...getCollectionsWithoutFilter.filter(d => !collectionName?.split(",")?.filter(s => s).some(c => [d.title, d.short_description].some(k => k.toUpperCase().includes(c.toUpperCase()))))
  ]
  // const getNeedToSaveProduct = useSelector((state) => state.auth?.needToSaveProduct);
  console.log("🚀 ~ RecentlyViewed ~ getCollections:", getCollections, getCollectionsWithoutFilter, collectionName, getCollectionsWithoutFilter.filter(d => collectionName?.split(",").some(c => [d.title, d.short_description].includes(c)))?.map(c => ({...c, searched : true})))

  // const { data: getCollectionsByProductData, ...getCollectionByProduct } = useFetchCollectionListQuery();
  
  const getCollectionsByProductData = toolsList
  // const [createCollection, { isLoading: createCollectionLoading }] = useCreateCollectionMutation();
 
  // const handleSubmitCollection = async (collectionTitle, _id, description) => {
  //   debugger;
  //   let getUpdatedCollections = getCollectionsWithoutFilter
  //   let getCollection = getUpdatedCollections?.find(d => d.title === collectionTitle)
  //   let getProduct = getUpdatedCollections?.find(d => d.title === collectionTitle)?.product || []
  //   const toggleProduct = getProduct.some(d => d._id === getNeedToSaveProduct._id) ? getProduct.filter(d => d._id !== getNeedToSaveProduct._id) : getProduct.concat(getNeedToSaveProduct)
  //   const message = getProduct.some(d => d._id === getNeedToSaveProduct._id) ? "Removed from" : "Added to"
  //   try { 
  //     const reqObj = {
  //       description: description || "", 
  //       _id: _id || collectionTitle ? collectionTitle?.toString()?.replaceAll(" ", '-')?.toLowerCase() : "",
  //       title: collectionTitle,
  //       product: toggleProduct?.filter(d => d),
  //       slug_url: collectionTitle ? collectionTitle?.toString()?.replaceAll(" ", '-')?.toLowerCase() : "",
  //       syncPending: true,
  //       // published_on: "",
  //       // is_published: false,
  //       // is_deleted: false,
  //       // added_by: "",
  //       // deleted_at: "",
  //       // added_at: "",
  //       // updated_at: "",
  //     };
  //     // if (response?.success) {
  //       dispatch(
  //         addNotification(
  //           message === 'Added to' ? "success" : "warning",
  //           "",
  //           `Your Tool ${message} ${reqObj?.title} Successfully`,
  //         )
  //       );
  //       // setCollectionName([])
  //       // if(token){
  //       //   getCollectionByProduct.refetch();
  //       // }
  //       const getCollectionsUpdated = getUpdatedCollections.some(d => d.title === reqObj.title) ? getUpdatedCollections?.map(d => d.title === reqObj.title ? ({...d, ...reqObj}) : d) : getUpdatedCollections.concat(reqObj)
  //       setLocalStorage(themeUserRecentlyViewedKey, getCollectionsUpdated?.filter(d => d))
  //       // window.dispatchEvent(new StorageEvent('storage', { key: themeUserRecentlyViewedKey }))
  //       handleClose()
  //     // }
  //   } catch (error) {}
  // };

  // const toggleCollection = (collection) => {
  //   const findCollection = getCollections.filter(d => d._id === collection._id)
  //   if(findCollection.some(d => d.slug_url === params?.product)){
  //     setCollectionName(collection)
  //   }else{
  //     setCollectionName(collection)
  //   }
  // }

  // useEffect(() => {
  //   if(getCollections && Array.isArray(getCollections)){
  //     setCollectionMatched(getCollections?.filter(d => d.product?.some(c => c?._id?.toLowerCase() === getNeedToSaveProduct?._id?.toLowerCase())))
  //   }
  // }, [getNeedToSaveProduct?._id])

  // useEffect(() => {
  //   const handleStorageChange = (event) => {
  //     debugger
  //     if (event.key === themeUserRecentlyViewedKey) {
  //       setCollections(getLocalStorage(themeUserRecentlyViewedKey) || [])
  //     }
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, []);

  return (
    <Offcanvas
      className="bg-light text-dark"
      isOpen={show}  // Use isOpen prop to control visibility
      direction="end"
      scrollable
      toggle={handleClose}
    >
      <OffcanvasHeader className="bg-primary" toggle={handleClose}>
        <div className="row">
          <div className="col">{getCollections?.length || ""} Recently Viewed Tools
            {/* <button className="btn p-0 ms-2" title="Sync Collections" onClick={() => syncCollections()}><i class="bi bi-arrow-repeat"></i></button> */}
          </div>
          {/* <div className="col-auto">
            <Link className="btn p-0" title="View All Saved tools" to="/saved">View All</Link>
          </div> */}
        </div>
      </OffcanvasHeader>  
      <div class={`input-group p-2 ${getCollections?.length > 0 ? "bg-light-primary shadow": ""}`}>
        <input
          type="text"
          class="form-control"
          placeholder="Search"
          aria-label="Button"
          aria-describedby=""
          onChange={(e) => setCollectionName(e.target.value)}
          value={collectionName}
        /> 
      </div>
      {getCollections?.length > 0 ? <OffcanvasBody>
        <div class="list-group">
          {getCollections?.map(d => (
            <a
            href="#"
            // onClick={() => {
            //   handleSubmitCollection(d.title, d._id);
            // }}
            class={`list-group-item rounded border my-1 list-group-item-action flex-column align-items-start ${d.searched ? 'bg-light-primary' : ""} ${d.matched ? 'bg-primary' : ""}`}
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{d.title}</h5>
              {/* <button className={`btn p-0`}><i className={`bi ${d.matched ? "bi-dash-lg" : "bi-plus"}`}></i></button> */}
            </div>
            {/* <p class="mb-1">Paragraph</p> */}
            <small class="text-muted">{d.short_description}</small>
          </a>
        ))}
        </div>
      </OffcanvasBody>  : 
      
      <div className="p-3 text-center">
        <p className="display-3 text-danger"><i className="bi bi-eye-slash-fill"></i></p>
        <p>Oops, Looks like You did Not View Any Tool</p>
        {/* <p>Click on Heart in Any of Tool And Create New Collection to save Tool</p> */}
      </div> }
      
    </Offcanvas>
  );
};

export default RecentlyViewed;
