
export const featuredData = [
    {
      imageUrl: {
        path: 'https://raw.githubusercontent.com/Evavic44/responsive-navbar-with-dropdown/main/assets/icons/botd.svg',
      },
      title: "Best of the day",
      description: "Shorts featured today by curators"
    },
    {
      imageUrl: {
        path: 'https://raw.githubusercontent.com/Evavic44/responsive-navbar-with-dropdown/main/assets/icons/botd.svg',
      },
      title: "Featured Streams",
      description: "Leading creatives livestreams"
    },
    {
      imageUrl: {
        path: 'https://raw.githubusercontent.com/Evavic44/responsive-navbar-with-dropdown/main/assets/icons/botd.svg',
      },
      title: "Subscriptions",
      description: "Gain exclusive access"
    },
    {
      imageUrl: {
        path: 'https://raw.githubusercontent.com/Evavic44/responsive-navbar-with-dropdown/main/assets/icons/botd.svg',
      },
      title: "Creative Feed",
      description: "See trending creations"
    }
  ]

export const mostSearchKeywords = [
    // {
    //     url: '#',
    //     text: "Explore all tools 🛠️",
    // },
    // {
    //     url: '#',
    //     text: "AI Use Cases 🤖",
    // },
    // {
    //     url: '#',
    //     text: "Free tools 💸",
    // },
    // {
    //     url: '#',
    //     text: "Opensource tools 📦",
    // },
    // {
    //     url: '#',
    //     text: "GPTs List 🧩",
    // },
    {
        url: '/all-categories',
        text: "All Categories 🎨"
    },
    {
        url: '/search',
        text: "All Tools 🧩"
    },
]

export const searchtags = [
"3D",
"Advertising",
"Analysis",
"Android App",
"App Builder",
"Art",
"Assistant",
"Audio",
"Automation",
"Avatar",
"Bot",
"Branding",
"Business",
"Career",
"Chatbot",
"Chrome Extension",
"Code",
"Community",
"Content",
"Conversation",
"Copy",
"Customer Support",
"Data",
"Design",
"Detector",
"Developer Tools",
"Doodle",
"Drawing",
"E-Commerce",
"Edge Extension",
"Education",
"Email",
"Experiment",
"Explainability",
"Feedback",
"Firefox Extension",
"Fun",
"Game",
"Gift",
"Image",
"Kids",
"Legal",
"Logo",
"Mac App",
"Machine Learning",
"Marketing",
"Marketplace",
"Mental Health",
"Mobile App",
"Monitoring",
"Music",
"NFT",
"No-Code",
"Observability",
"Open Source",
"Outreach",
"Podcast",
"Presentations",
"Productivity",
"Prompt",
"Research",
"SEO",
"Sales",
"Science",
"Search",
"Singing",
"Social Media",
"Speech",
"Startup",
"Story",
"Summary",
"Tablet App",
"Text",
"Therapy",
"Tracking",
"Transcription",
"Travel",
"Video",
"Voice",
"Waitlist",
"Web Development",
"WordPress",
"Writing",
]