import React, { lazy, useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { featuredData, mostSearchKeywords } from "../data";
import BreadCrumb from "../layout/BreadCrumb";
import * as Yup from "yup";
import {
  useCreateCollectionMutation,
  useCreateReviewMutation,
  useFetchCurrenciesQuery,
  useFetchHighlightProductQuery,
  useFetchIncreaseProductQuery,
  useFetchProductBySlugQuery,
  useFetchReviewsByProductIdQuery,
} from "../redux/api/endpoints";
import {
  CardInlineIconImageComponent,
  CreateIframeAsImage,
  handleSubmitCollection,
} from "../components/TabsAndCards";

import { getColonHeadingAndText, getLocalStorage, setLocalStorage } from "../helpers/Utils";
import ReviewForm from "../components/ReviewAndRating";
import { Formik, FormikProvider, useFormik } from "formik";
import { Form } from "reactstrap";
import { addNotification } from "../redux/notificationSlice";
import { useDispatch } from "react-redux";
import { themeUserCollectionKey, themeUserRecentlyViewedKey, themeUserStorageKey } from "../config/config";
import { addProduct } from "../redux/slices/productSlice";
import { needToSaveProduct } from "../redux/slices/authSlice";
import { toolsList } from "../data/tools.js";

const DataVisualization = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./data-visualization/DataVisualization.js")
);
const FileHandlerComponent = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./download-and-printing/FileHandlerComponent.js")
);
const JSONDIFF = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./json-tools/JSONDiff.js")
);
const JSONClearValues = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./json-tools/JSONClear.js")
);
const JSONRemoveClearValues = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./json-tools/JSONRemoveClearValues.js")
);
const JSONKeyRemover = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./json-tools/JSONKeyRemover.js")
);
const JSONMerger = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./json-tools/JSONMerger.js")
);
const JSONSplitter = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./json-tools/JSONSplitter.js")
);
const JSONDataobfuscator = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./json-tools/JSONDataObfuscator.js")
);
const JSONTransformer = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./json-tools/JSONTransformer.js")
);
const JsonSchemaGenerator = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./json-tools/JSONSchemaGenerator.js")
);
const ResponsiveView = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./ui-tools/ResponsiveView.js")
);

// TEXT TOOLS
const TEXTDIFF = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./text-tools/TextDiff.js")
);

// CSS TOOLS
const CSSGridLayoutGenerator = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./css-tools/CSSGridLayoutGenerator.js")
);
// SEO TOOLS
const SitemapGenerator = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./seo-tools/SitemapGenerator.js")
);
const HTMLMetaTagGenerator = lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./seo-tools/HTMLMetaTagGenerator.js")
);

const Product = ({children}) => {
  console.log("🚀 ~ Product ~ children:", children)
  const params = useParams();
  const dispatch = useDispatch();
  const [hasStayed, setHasStayed] = useState(false);

  // const { data: getHighlightProductsData, ...getHighlightProducts } =
  //   useFetchHighlightProductQuery();
  // const { data: getProductBySlugData, ...getProductBySlug } =
  //   useFetchProductBySlugQuery(params.product || "");
  // const productId = getProductBySlugData?.data?._id;
  const productId = toolsList.find(d => d.slug_url === params.product).id;
  console.log("🚀 ~ Product ~ params.product:", params.product)
  // const { data: getIncreaseCategoryData, ...getIncreaseCategory } =
  //   useFetchIncreaseProductQuery(productId, {
  //     skip: !productId, // Skip the query if categoryId is not present
  //   });
  // const { data: getReviewByProductIdData, ...getReviewByProductId } =
  //   useFetchReviewsByProductIdQuery(productId, {
  //     skip: !productId, // Skip the query if categoryId is not present
  //   });
  // console.log("TCL: getReviewByProductIdData", getReviewByProductIdData);
  // const [createReview, { isLoading: createReviewLoading }] =
  //   useCreateReviewMutation();
  
  // const [createCollection, { isLoading: createCollectionLoading }] =
  // useCreateCollectionMutation();

  // const { data: getcurrenciesData, ...getCurrencies } =
  //   useFetchCurrenciesQuery(); 


  // // useEffect(() => {
  // //   if (getProductBySlugData) {
  // //     dispatch(addProduct(getProductBySlugData.data));
  // //   }
  // // }, [dispatch, getProductBySlugData]);

  // const currencies =
  //   getcurrenciesData?.data?.map((d) => ({
  //     label: d.symbol,
  //     value: d.value,
  //   })) || [];
  // const [showLivePricingDetail, setShowLivePricingDetail] = useState(false);
  // const [openRateIt, setOpenRateIt] = useState(false);
  // const getUser = getLocalStorage(themeUserStorageKey);
  // const card = {
  //   ...getProductBySlugData?.data,
  //   prices: getProductBySlugData?.data?.prices.filter(
  //     (d, index) => index !== 0
  //   ),
  // } || { prices: [] };
  // const cardFeatures = {
  //   ...getProductBySlugData?.data,
  //   prices: getProductBySlugData?.data?.prices.filter(
  //     (d, index) => index === 0
  //   ),
  // } || { prices: [] };
  // console.log("🚀 ~ product ~ params:", params, cardFeatures);

  // const handleSubmitReview = async (e) => {
  //   debugger;
  //   console.log("TCL: req", e);
  //   try {
  //     debugger;
  //     const reqObj = {};
  //     e.review?.forEach((element) => {
  //       reqObj[element.name] = element.rating || element.value;
  //     });
  //     reqObj.product_id = productId;
  //     reqObj._id = e._id;
  //     const { data } = await createReview(reqObj);
  //     dispatch(
  //       addNotification(
  //         data?.success ? "success" : "danger",
  //         "",
  //         data?.msg || "Your Review Saved Successfully"
  //       )
  //     );
  //     if (data?.success) {
  //       reviewFormik.resetForm();
  //       getReviewByProductId.refetch();
  //     }
  //   } catch (error) {}
  // };

  // const handleSubmitCollection = async (e) => {
  //   debugger
  //   if(productId){
  //     dispatch(needToSaveProduct(productId)) 
  //     // setLocalStorage("needToSaveProduct", productId)
  //   }
  // };

  // const handleIncreaseRating = (name, formik) => {
  //   const findedIndex = formik.values.review.findIndex(d => d.name === name)
  //   formik.setFieldValue(`review.${findedIndex}.value`, Number(formik.values?.review?.[findedIndex]?.value || 0) + 1)
  // }

  // const reviewPageInitial = (data) => [
  //   {
  //     name: "valueForMoney",
  //     label: "Value For Money",
  //     rating: data?.valueForMoney || "",
  //   },
  //   {
  //     name: "easeOfUse",
  //     label: "Ease Of Use",
  //     rating: data?.easeOfUse || "",
  //   },
  //   {
  //     name: "performance",
  //     label: "Performance",
  //     rating: data?.performance || "",
  //   },
  //   {
  //     name: "featuresAvailable",
  //     label: "Features Available",
  //     rating: data?.featuresAvailable || "",
  //   },
  //   {
  //     name: "supportQuality",
  //     label: "Support Quality",
  //     rating: data?.supportQuality || "",
  //   },
  //   {
  //     name: "recommandableValue",
  //     label: "Likelihood to Recommend",
  //     rating: data?.recommandableValue || "",
  //   },
  //   {
  //     name: "upVote",
  //     label: "Up Vote",
  //     value: data?.upVote || "",
  //     fieldProps: {
  //       hidden: true,
  //     }
  //   },
  //   {
  //     name: "downVote",
  //     label: "Down Vote",
  //     value: data?.downVote || "",
  //     fieldProps: {
  //       hidden: true,
  //     }
  //   },
  //   {
  //     name: "goodReview",
  //     label: "Do you like Something in this Tool?",
  //     answerlabel: "I liked about this is that",
  //     value: data?.goodReview || "",
  //     fieldProps: {
  //       type: "textarea",
  //       hidden: false,
  //       }
  //   },
  //   {
  //     name: "badReview",
  //     label: "What is Problem in this Tool?",
  //     answerlabel: "The Problem, I faced is that",
  //     value: data?.badReview || "",
  //     fieldProps: { 
  //       type: "textarea",
  //       hidden: false,
  //     }
  //   },
  // ];

  // const reviewFormik = useFormik({
  //   initialValues: {
  //     review: reviewPageInitial(),
  //   },
  //   enableReinitialize: true,
  //   validationSchema: Yup.object().shape({
  //     review: Yup.array().of(
  //       Yup.object().shape({
  //         rating: Yup.number().test({
  //           name: "is-required",
  //           skipAbsent: false,
  //           test(value, context) {
  //             debugger;
  //             const review =
  //               context.from[context.from?.length - 1].value.review;
  //             const index = context.path?.slice(
  //               context.path.indexOf("[") + 1,
  //               context.path.indexOf("]")
  //             );
  //             const retuenValue =
  //               !value && Object.keys(review[index]).includes("rating");
  //             console.log(
  //               "sososooso",
  //               retuenValue,
  //               index,
  //               review,
  //               Object.keys(review[index]).includes("rating")
  //             );
  //             if (retuenValue) {
  //               return context.createError({
  //                 message: "This Field is Required",
  //               });
  //             }
  //             return true;
  //           },
  //         }),
  //       })
  //     ),
  //   }),
  //   onSubmit: handleSubmitReview,
  // });

  console.log("🚀 ~ handleSubmitRecentlyViewed ~ window.location.pathname:", window.location.pathname)
  const handleSubmitRecentlyViewed = async (e) => {
    debugger
    let getRecentlyViewed = getLocalStorage(themeUserRecentlyViewedKey)?.length > 0 ? getLocalStorage(themeUserRecentlyViewedKey) : [] 
    if(productId && params.product && window.location.pathname.split("/")?.length === 3){
      getRecentlyViewed = getRecentlyViewed.filter(d => d.id !== productId)
      getRecentlyViewed.unshift(toolsList.find(d => d.id === productId))
      const toSetLocal = getRecentlyViewed?.filter(d => d)
      console.log("🚀 ~ handleSubmitRecentlyViewed ~ toSetLocal:", toSetLocal)
      setLocalStorage(themeUserRecentlyViewedKey, toSetLocal)
    }
  };


  // useEffect(() => {
  //   // Start a timer when the component mounts
  //   const timer = setTimeout(() => {
  //     handleSubmitRecentlyViewed();
  //     setHasStayed(true);  // Mark that the user has stayed for 30 seconds
  //   }, 3000); // 30 seconds

  //   // Cleanup the timeout if the user leaves before 30 seconds
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 30) { // Check if user has scrolled 30px or more
        handleSubmitRecentlyViewed();
        setHasStayed(true);
        window.removeEventListener('scroll', handleScroll); // Remove the event listener once triggered
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  console.log("🚀 ~ renderComponent ~ params?.product:", params?.product)
  const renderComponent = () => {
      switch (params?.product) {
        case 'data-visualization':
          return <DataVisualization />;

        case 'download-and-print':
          return <FileHandlerComponent />;

        case 'json-diff':
          return <JSONDIFF />;

        case 'json-clear-values':
          return <JSONClearValues />;

        case 'json-remove-empty-keys':
          return <JSONRemoveClearValues />;

        case 'json-keys-remover':
          return <JSONKeyRemover />;

        case 'json-merger':
          return <JSONMerger />;

        case 'json-splitter':
          return <JSONSplitter />;

        case 'json-data-obfuscator':
          return <JSONDataobfuscator />;

        case 'json-transformer':
          return <JSONTransformer />;

        case 'json-schema-generator':
          return <JsonSchemaGenerator />;

        case 'text-difference-and-comparison':
          return <TEXTDIFF />;
        
        case 'responsive-view':
          return <ResponsiveView />;

        case 'css-grid-layout-generator':
          return <CSSGridLayoutGenerator />;

        case 'sitemap-generator':
          return <SitemapGenerator />;
          
        case 'html-meta-tag-generator':
          return <HTMLMetaTagGenerator />;

        default:
          return <div>404 - Page Not Found</div>;
      }
    };

  return params?.product ? (
    <section>
      <div className="container">
        <div className="row">
          <div className="col">
            <BreadCrumb />
          </div>
          {/* <div className="col-auto">
            <button type="button" onClick={() => handleSubmitCollection()} className="ms-auto px-3 btn d-flex align-items-center">
              <span className="h5"> Save </span>{" "}
              <span className="ms-2 mb-2">
                <i className="fs-4 bi bi-heart"></i>
              </span>
            </button>
          </div> */}
        </div> 
        <div className="row">
          <div className="col-12">
            {renderComponent()}
          </div>
        </div>
      </div>
    </section> 
  ) : (
    <Outlet />
  );
};

export default Product;
